@use "../../../theme/vars.scss";

.helperText {
  margin-top: -20px;
}
.controls {
  &[disabled] {
    pointer-events: none;
  }

  flex-grow: 1;
  .controlLevel {
    min-height: 50px;
    padding: 5px;
    margin: 0;
    // Draw borders as placeholders so when the color changes it doesn't get displaced
    border-right: 3px solid transparent;
    border-left: 5px solid #aaa;
    &.active {
      border-left-color: var(--color-primary);
      .roundIcon {
        background: var(--color-primary);
      }
      & ~ .controlLevel {
        border-left-color: var(--color-primary);
        .roundIcon {
          background: var(--color-primary);
        }
      }
    }
    &:hover {
      background-color: #eee;

      & ~ .controlLevel {
        background-color: #eee;
      }
    }
  }
  //  Can't draw borders for top and bottom as that will create indents in the corners (imagine picture frame with one of the sides missing)
  // Cover the hovering as .controlLevel.active is more specific then :hover.controlLevel.hover
}

.error {
  color: var(--color-error);
}
.okAlert {
  margin-top: 10px;
}
.readonlyToken {
  background-color: var(--color-gray-lighter);
  width: 100%;
  height: 35px;
  padding: 6px 12px;
  border: 1px solid var(--color-gray-light);
  color: var(--color-gray-dark);
  border-radius: 4px;
  box-shadow: vars.$shadow;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}
div.roundIcon {
  max-width: 50px;
  min-width: 50px;
  max-height: 50px;
  min-height: 50px;
  background-color: #888;
  svg {
    width: 1.33rem;
    height: 1.33rem;
  }
}
