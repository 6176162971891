@use "../../theme/vars.scss";

$extraLarge: 120px;
$large: 80px;
$medium: 50px;
$small: 30px;
$tiny: 1.2rem;
$fontSizeModifier: 0.5;
@mixin sizeAvatar($dim) {
  img,
  .letter {
    height: $dim;
    width: $dim;
  }

  object-fit: cover;
  .letter {
    font-size: $dim * $fontSizeModifier;
    line-height: $dim;
  }
}
.avatar {
  color: var(--color-gray);
  a {
    height: inherit;
    width: inherit;
  }
  img {
    display: block;
    object-fit: cover;
    border-radius: 50%;
  }
  &.square {
    img,
    .letter {
      border-radius: 3px;
    }
  }
  //default:medium
  @include sizeAvatar($medium);

  &.avatar-xs {
    @include sizeAvatar($tiny);
  }
  &.avatar-sm {
    @include sizeAvatar($small);
  }
  &.avatar-md {
    @include sizeAvatar($medium);
  }
  &.avatar-lg {
    @include sizeAvatar($large);
  }
  &.avatar-xl {
    @include sizeAvatar($extraLarge);
  }
}
.letter {
  width: 100%;
  height: 100%;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  font-family: "Lucida Console", Monaco, monospace;

  text-align: center;
  text-transform: uppercase;
  // color: rgb(255, 255, 255);
  // color:$custom1;
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  background: rgb(103, 174, 63);
  user-select: none;
}

.isLink {
  cursor: pointer;
}
