@use "../../theme/vars.scss";

.editSlug {
  margin-top: 10px;
  font-size: 13px;
  color: #757575;
  padding: 0;
}

.adornment {
  max-width: 60%;
  direction: rtl;
  div {
    overflow: hidden;
  }
  span {
    display: inline-block;
    direction: ltr;
    white-space: nowrap;
  }
}
