@use "../../../../../theme/vars.scss";

.galleryContainer {
  position: relative;
}

.missingVar {
  color: var(--color-warning);
}
.configContainer {
  min-height: 550px;
}
.configSection {
  min-height: 100px;
}
.baseFormWidth {
  min-width: 200px;
}
.exampleWidgetContainer {
  align-self: center;
}
.exampleWidget {
  width: 300px;
  height: 490px;
  overflow-y: auto;
  background-color: var(--color-gray-lighter);
}

.selectWithEndAdornment {
  :global(.MuiSelect-icon) {
    margin-right: 1rem;
  }
}
.subItemWrapper {
  background-color: var(--color-gray-lighter);
  padding: 0.3rem;
  border-radius: 6px;
}
.gallery {
  word-wrap: break-word;

  :global(.MuiMasonry-root) {
    margin: 0;

    a:hover,
    a:focus {
      color: inherit;
      text-decoration: none;
    }

    //Making the masonry display as a single column in print preview
    @media print {
      display: block;
      height: fit-content;
    }
  }

  //Ensuring each gallery item occupies the full page width in print preview
  :global(.MuiPaper-outlined) {
    @media print {
      break-inside: avoid;
    }
  }
}

.galleryCardContent {
  img,
  video {
    max-height: 300px;
    max-width: 100%;
  }
  audio {
    width: 100%;
  }

  //Removing audio controls from print preview
  @media print {
    audio {
      display: none;
    }
  }
}

.galleryFigureCaption {
  img,
  video {
    max-height: 300px;
    max-width: 100%;
  }
  video {
    width: 100%;
    max-height: 300px;
  }
  text-align: center;

  //Removing audio controls from print preview
  @media print {
    audio {
      display: none;
    }
  }
}
