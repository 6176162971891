@use "../../theme/vars.scss" as theme-vars;
@use "../../theme/zIndexes.scss";
@use "vars.scss";

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media print {
    min-height: unset;
    *::-webkit-scrollbar {
      display: none;
    }
    * {
      scrollbar-width: none;
    }
  }
}

.withToolbar {
  margin-top: theme-vars.$console-navbar-height;
  @media print {
    margin-top: 0;
  }
}

.bottomMargin {
  margin-bottom: 2.66rem;

  @media print {
    margin-bottom: 0;
  }
}

@media #{vars.$showLargePanelOnSize} {
  .withSidePanel {
    margin-left: vars.$panelWidthOpen;
    transition: margin-left vars.$animateDuration vars.$panelEase;
    &.sidePanelCollapsed {
      margin-left: vars.$panelWidthCollapsed;
    }
    @media print {
      margin-left: 0;
    }
  }
}

.navbar {
  .impersonateBtn {
    color: var(--color-warning);
    font-size: 30px;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px 10px;
  z-index: zIndexes.$navbar-container-z;
  height: theme-vars.$navbar-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-banner);
  box-shadow:
    0 3px 4px 0 rgba(0, 0, 0, 0.1),
    0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0;
  .navBarLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 48px; //needed for ellipsis to work: https://css-tricks.com/flexbox-truncated-text/
  }
  .navBarRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .hamburgerBtn {
    color: var(--color-primary-contrast);
    @media screen and (min-width: theme-vars.$screen-sm-min) {
      display: none;
    }
  }
  .breadCrumbs {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-primary-contrast);
    .separator {
      padding: 0px 10px;
    }
    font-size: 17px;
    * {
      color: var(--color-primary-contrast);
      &:hover,
      &:active,
      &:focus {
        color: var(--color-primary-contrast);
      }
      text-decoration: inherit;
    }
  }
  .authBtnWrapper {
    display: flex;
    margin-left: 10px;
    button {
      margin: 7.25px 1.5px;
      &.login {
        color: var(--color-primary);
        background-color: white;
        &:hover {
          background-color: var(--color-gray-lighter);
        }
        &:focus-visible {
          border: theme-vars.$focus-border;
        }
        &:disabled {
          color: var(--color-gray-light);
          background-color: var(--color-gray-lighter);
        }
      }
      &.register {
        color: white;
        background-color: var(--color-secondary);
        &:hover {
          filter: brightness(1.1);
        }
        &:focus-visible {
          border: theme-vars.$focus-border;
        }
        &:disabled {
          filter: saturate(0.7);
        }
      }
    }
  }
  @media print {
    display: none;
  }
}

.brandWrapper {
  font-size: 19px;
  line-height: 21px;
  height: theme-vars.$navbar-height;
  display: flex;
  justify-content: center;
  a,
  span {
    display: flex;
    align-items: center;
  }
}

.draggingFileOverlay {
  background-color: var(--color-gray);
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 100%;
  bottom: 100%;
  z-index: zIndexes.$navbar-mask-z; //should be less than ds panel
  transition: opacity vars.$animateDuration ease;
}

.draggingFile {
  //disable pointer events. needed to avoid false dragLeave events
  //see https://stackoverflow.com/a/18582960/1052020
  * {
    pointer-events: none;
  }
  .draggingFileOverlay {
    right: 0;
    bottom: 0;
    opacity: 0.4;
  }
}

nav.navbar.frontPage {
  background: transparent;
  box-shadow: none;
  transition:
    background-color 0.5s ease,
    box-shadow 0.5s ease;
  padding-right: 20px;

  .quickNavSearch {
    transition: opacity 0.2s ease;
    opacity: 0;
    pointer-events: none;
  }
  &:global(.belowHeader) {
    background: var(--color-banner);
    box-shadow:
      0 3px 4px 0 rgba(0, 0, 0, 0.1),
      0 3px 3px -2px rgba(0, 0, 0, 0.2),
      0 1px 8px 0;

    .quickNavSearch {
      transition: opacity 0.5s ease;
      opacity: 1;
      pointer-events: all;
    }
  }
}

//css trickery for quick nav search on navbar to collapse and extend on smaller screens without involving component changes/logic
.quickNavSearch {
  width: 280px;
  min-width: 280px;
  opacity: 1;
}

@media screen and (max-width: theme-vars.$screen-xs-max) {
  .quickNavSearch {
    width: 94px;
    min-width: 94px;

    &:focus-within {
      width: 300px;
      min-width: 300px;
    }
  }
}
