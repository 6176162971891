@use "../../theme/vars.scss";

.box {
  border: 1px solid var(--color-gray-light);
  border-radius: 5px;
}

.boxHighlighted {
  border: 1px solid var(--color-primary);
}

.label {
  background: white;
  margin-left: 1rem;
  display: inline-block;
  margin-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  transform: translate(0px, -0.6rem) scale(0.75);
}

.tabs:global(.MuiTabs-root) {
  border-bottom: 1px solid var(--color-gray-light);
  margin-top: -20px;
  height: 32px;
  min-height: 32px;
}

.tabsHighlighted {
  border-bottom: 1px solid var(--color-primary);
}

button.tab {
  min-height: 32px;
  min-width: 100px;
  height: 32px;
  padding: 6px 12px; // Previous Mui
}

.tabPanel {
  padding: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tabContent {
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.markdown {
  background: var(--color-gray-lighter);
  overflow-x: auto;
  max-height: 20rem;
}

.inputField textarea {
  resize: vertical;
}

.inputField > div {
  padding: 0;
}

.error div {
  border-color: var(--color-error);
}
