.image {
  max-width: 100px;
  max-height: 42px;
  margin-right: 10px;
  float: left;
}
.spinner {
  float: left;
  margin-top: 3px;
  margin-right: 7px;
}
