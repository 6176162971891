@use "../../theme/vars.scss";

$table-border-color: #edf3f8;
.markup {
  overflow: auto;
  position: relative;

  :global(.tableWrapper) {
    margin-block-start: 1em;
    margin-block-end: 1em;
    overflow: auto;
    word-break: normal;
    table {
      border-collapse: collapse;
      thead {
        th {
          padding: 0.3rem 0.6rem;
          border-bottom: 2px solid $table-border-color;
          font-weight: bold;
        }
      }
      tbody {
        tr {
          vertical-align: top;
          td {
            padding: 0.3rem 0.6rem;
            border-bottom: 1px solid $table-border-color;
            border-right: 1px solid $table-border-color;
            &:last-child {
              border-right: 0;
            }
          }
        }
      }
    }
  }
  :global(.mermaid) {
    visibility: hidden;
    overflow: auto;
    font-family: "trebuchet ms", verdana, arial;
  }
  :global(.mermaid)[data-processed="true"] {
    visibility: visible;
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
  &.compact {
    p,
    ul {
      margin: 0;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.2;

    + p {
      margin-top: 0;
    }
  }
  h1 {
    font-size: 1.6rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  h4 {
    font-size: 1.15rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.9rem;
  }
  pre {
    overflow: auto;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  img {
    //Avoid images introducing scrollbars
    max-width: 100%;
  }
}
