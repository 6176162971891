.container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 10px;
}

.labelContainer {
  flex-grow: 1;
  width: 200px;
  word-wrap: break-word;

  .label {
    font-weight: bold;
    &.required::after {
      content: "*";
    }
  }

  .helperText {
    font-size: 90%;
  }
}

.field {
  flex-grow: 1000000;
  min-width: #{"min(100%, 500px)"}; // Workaround to prevent Sass error
}

.readOnly {
  color: var(--color-gray);
}
