@use "../../theme/vars.scss";

div.widget {
  border-radius: 2px;
  padding: 20px;
  word-break: break-word;
  padding-top: 10px;
}

.header {
  align-items: center;
  width: 100%;
  ~ div {
    margin-top: 10px;
  }
}
.exampleResources {
  display: flex;
  flex-wrap: wrap;
  gap: 8.5px;
}

div.markdown {
  overflow: hidden;
}
.indent {
  display: flex;
  flex-direction: column;
  border-left: 3px solid var(--color-primary);
  padding: 0.3rem 0.6rem;
}

.small {
  h2 {
    font-size: 1.733em; // Style h2 as h3
  }
}

.darkLink {
  color: var(--color-gray-dark);
  &:hover {
    color: var(--color-gray);
  }
}
