.legendItem {
  height: 20px;
  width: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.idleWorkers {
  @extend .legendItem;
  background-color: var(--color-gray-lighter);
}

.activeTasks {
  @extend .legendItem;
  background-color: var(--color-warning);
}

.pendingTasks {
  @extend .legendItem;
  background-color: var(--color-error);
}
