@use "../../../theme/vars.scss";

.form {
  display: flex;
  flex-direction: column;
}
.checkBox {
  padding: 0px;
  margin-right: 20px;
}
div.datasetSelect {
  margin: 10px 20px;
  width: 256px;
}

div.graphWrapper {
  margin: 10px 20px;
  flex-grow: 1;
}
.arrow {
  margin: 4px 10px;
}
.graphs {
  & > h5 {
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 6px;
  }
}
.submit {
  margin-top: 20px;
}
.showMore {
  width: 100%;
  display: flex;
  justify-content: center;
}
.graphList {
  margin: 8px 0px;
}

.graph {
  display: flex;
  padding: 7px 3px 18px 0px;

  &:hover {
    background-color: #f5f5f5;
  }

  .from {
    flex-grow: 1;

    & > input {
      border-bottom: none;
    }
  }

  & > i {
    padding: 4px 10px;
  }

  .to {
    flex-grow: 1;
  }
}

.error {
  font-size: 12px;
  color: var(--color-error);
  border-top: 2px solid var(--color-error);
  padding-top: 3px;
}
