@use "../../../theme/vars.scss";

.featureToggle {
  display: flex;
  align-items: center;
  .featureToggleLabel {
    padding-right: 20px;
  }
  .featureToggleDescription {
    flex-grow: 1;
  }
}
