@use "index.scss";

.dragHandle {
  cursor: ns-resize;
}

.paragraphWrapper + .paragraphWrapper > .storyElement {
  margin-top: 1rem; // Reduce the margin top on chained paragraph items
}

.storyElement {
  box-sizing: border-box;
  &.editable {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  .storyElementToolbar {
    @extend .constrainWidth;
    justify-content: flex-end;
  }
  .warningElement {
    @extend .constrainWidth;
  }
  &.dragging {
    visibility: hidden;

    :global(.mermaid[data-processed="true"]) {
      visibility: hidden;
    }
  }

  &.editable:hover:not(.dragging) {
    border-left-color: var(--color-primary);
  }
}

.dragElement {
  margin-top: -40px;

  .storyElement {
    background-color: #f5f5f5;
    border-left: 5px solid var(--color-primary);
  }
}

.dragging {
  user-select: none;
}

.warningIcon {
  color: var(--color-warning);
}

@media print {
  .storyElementToolbar {
    display: none;
  }
}
.mockElement {
  cursor: ns-resize;
  margin-top: -40px;
  background-color: #f5f5f5;
  border-left: 5px solid var(--color-primary);
  .query {
    @extend .constrainWidth;
    height: 400px;
  }
}
