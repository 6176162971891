@use "../../../theme/vars.scss";

.editor {
  .unusedVar span {
    // This should be the same gray that is used as the comment
    color: #6e7781;
  }
  .highlighter {
    border: 1px solid var(--color-warning);
    background-image: none;
  }
  :global(.cm-tooltip) {
    background: white;
    box-shadow: vars.$shadow;
    border: none;
  }
  :global(.cm-diagnostic) {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding: 0;
    max-width: 500px;

    :global(.cm-diagnosticText):empty {
      display: none;
    }
    & > span {
      padding: 3px;
      padding-left: 0.5rem;
    }
    &:global(.cm-diagnostic-warning) {
      border-left-color: var(--color-warning);
    }
    &:global(.cm-diagnostic-error) {
      border-left-color: var(--color-error);
    }
    &:global(.cm-diagnostic-info) {
      border-left-color: var(--color-gray-light);
    }
    &:global(.cm-diagnostic-hint) {
      border-left-color: var(--color-info);
    }
    :global(.cm-diagnosticAction) {
      background: white;
      color: black;
      margin-left: 0;
      border-radius: 0;
      text-align: start;
      border-top: 1px solid var(--color-gray-lighter);
      font-size: small;
      padding-left: 1rem;
      &:hover {
        background: var(--color-gray-lighter);
      }
      &::before {
        content: "🔧 ";
      }
    }
  }
}
