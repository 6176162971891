.otpErrorText {
  color: var(--color-error);
  font-size: 0.9rem;
}

.center {
  text-align: center;
}

.recoveryCodes {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
}

.italic {
  font-style: italic;
}
.breakable {
  word-break: break-all;
}
