.apiPathLabel {
  font-weight: bold;
}
.authLevelIcon {
  font-size: 1em;
  display: flex;
}
.authDescription {
  align-items: center;
}
.tokenLabel {
  font-weight: bold;
}
