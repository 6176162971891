.moveAccount {
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .moveAccountName {
    text-align: center;
    word-break: break-all;
    word-wrap: break-word;
    max-width: 80px;
  }
}
