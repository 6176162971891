@use "../../theme/vars.scss";
@use "../../theme/zIndexes.scss";

@font-face {
  font-family: "CMU Serif";
  src: local("CMU Serif"), url("./fonts/cmunrm.otf");
}
@font-face {
  font-family: "CMU Serif Bold";
  src: local("CMU Serif Bold"), url("./fonts/cmunbx.otf");
  font-weight: bold;
}
@font-face {
  font-family: "CMU Serif Italic";
  src: local("CMU Serif Italic"), url("./fonts/cmunti.otf");
  font-style: italic;
}
@font-face {
  font-family: "CMU Serif Bold Italic";
  src: local("CMU Serif Bold Italic"), url("./fonts/cmunbi.otf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "CMU Serif Monospace";
  src: local("CMU Serif Monospace"), url("./fonts/cmuntt.otf");
}

@page lncs {
  size: A4;
  margin-top: 4.3cm;
  margin-bottom: 6cm;
}

.constrainWidth {
  margin-left: auto;
  margin-right: auto;
  max-width: #{"min(900px, calc(100vw - 2rem))"};
}

.container {
  background: white;
}

.lncs {
  page: lncs;
  font-size: 10pt !important;
  margin: 0 auto !important;
  padding: 0 !important;
  width: 122mm !important;
  color: black !important;
  background-color: white !important;
  font-family: "CMU Serif", serif !important;
  line-height: 1.231 !important;

  .content {
    h1 {
      text-align: left !important;
    }

    h1,
    h2 {
      overflow: hidden !important;
      font-family: "CMU Serif Bold", serif !important;
    }

    h1 {
      font-size: 12pt !important;
      margin-top: 1.5em !important;
      margin-bottom: 0.9em !important;
    }

    h2 {
      font-size: 10pt !important;
      margin-top: 1.35em !important;
      margin-bottom: 0.75em !important;
    }

    h3,
    h4,
    h5,
    h6 {
      display: inline !important;
      font-size: 10pt !important;
    }

    h3:after {
      font-weight: bold !important;
      content: ". " !important;
    }

    h4,
    h5,
    h6 {
      font-weight: normal !important;
    }

    h4:after,
    h5:after,
    h6:after {
      content: ". " !important;
    }

    h3 + p,
    h4 + p,
    h5 + p,
    h6 + p {
      display: inline !important;
    }

    table {
      border-collapse: collapse !important;
      width: 100% !important;
      margin-bottom: 1em !important;
      border-top: 2px solid black !important;
      border-bottom: 2px solid black !important;
      font-size: 8pt !important;
      word-break: break-all !important;
    }

    th,
    td {
      padding-top: 0.25em !important;
      padding-bottom: 0.25em !important;
      padding-right: 1em !important;
      text-align: left !important;
      border: 0 !important;
    }

    tr {
      vertical-align: top !important;
      border-bottom: 1px solid black !important;
    }

    tr:last-child {
      border-bottom: 0 !important;
    }

    th:last-child,
    td:last-child {
      padding-right: 0 !important;
    }

    table p {
      text-indent: 0 !important;
    }

    a {
      text-decoration: underline !important;
      color: black !important;
      word-break: break-all !important;
    }

    strong {
      font-family: "CMU Serif Bold", serif !important;
    }

    em {
      font-family: "CMU Serif Italic", serif !important;
    }

    h1 em,
    h2 em,
    h3 em,
    strong em,
    em strong {
      font-family: "CMU Serif Bold Italic", serif !important;
    }

    code,
    pre {
      font-family: "CMU Serif Monospace", monospace !important;
      font-size: 8pt !important;
    }

    p {
      text-align: justify;
      margin-bottom: 0px;
      margin-top: 0px;
      a {
        word-break: normal !important;
      }
    }

    p + p {
      text-indent: 1em;
    }
  }
}

.fabWrapper {
  display: flex;
  z-index: zIndexes.$second-navbar-z - 1;
  position: fixed;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  right: 16px;
  &.top {
    top: calc(#{vars.$navbar-height} + 16px);
  }
  &.bottom {
    bottom: 16px;
  }

  .editIcon {
    font-size: 1.3rem;
  }
}

.story {
  overflow: hidden;
}

figcaption {
  font-size: 95%;
}
