.header {
  display: flex;
  align-items: center;
  & > * {
    margin-right: 1rem;
  }
}
.mainTask {
  display: flex;
  align-items: center;
}

.taskExecutionDescription {
  flex-grow: 1;
}
.historyList {
  margin-left: 2rem;
  font-size: 0.9rem;
  .taskExecution:last-of-type ~ hr {
    display: none;
  }
}
.taskExecution {
  display: flex;
  align-items: center;

  dl {
    margin-top: 0.5rem;
  }

  dt {
    padding-left: 0;
  }
}
.textContent {
  flex-grow: 1;
}
pre.taskExecutionReport {
  padding: 0;
  margin: 0;
  background: transparent;
}
.actions,
.taskExecutionActions {
  display: flex;
  flex-direction: row;
}
.actions > *,
.taskExecutionActions > * {
  margin-left: 0.7rem;
}
.cacheHeaderToggle {
  align-self: center;
  width: auto;
}

.space {
  flex-grow: 1;
  flex-basis: 1rem;
}
