@use "../../theme/vars.scss";

.fileRow {
  display: flex;
  align-items: center;
  padding: 5px 4px;
  .label {
    display: flex;
    flex-grow: 1;
    align-items: center;
    &.uploading {
      color: #888;
    }
    .fileSize {
      margin-left: 10px;
      color: var(--color-gray);
      font-size: 85%;
      align-self: middle;
    }
    .fileName {
      font-family: "Lucida Console", Monaco, monospace;
    }
  }

  &:hover {
    background-color: #f5f5f5;
  }

  :global(.progress) {
    width: 100px;
    height: 10px;
    margin-bottom: 0px;
    margin-right: 10px;
  }
}
