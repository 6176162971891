@use "../../../theme/vars.scss";

.bulkResult {
  border: 1px solid var(--color-gray-light);
  padding: 10px;

  > div + div {
    margin-top: 20px;
  }
}

.actions {
  text-align: right;
  white-space: nowrap;
}
