@use "../../theme/vars.scss";

.searchBanner {
  background-color: white;
  border-bottom: 1px solid #ddd;

  transition: min-height 0.3s;
  min-height: 0;

  display: flex;
  flex-direction: column;

  &.onSearchPage {
    min-height: 100%;
  }
}

.outerSearchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.innerSearchContainer {
  flex-grow: 1;
  max-width: 500px;
}

.tabContainer {
  display: flex;
  flex-direction: row;
}

.tab {
  cursor: pointer;
  padding: 10px 16px;
}

.tab:focus {
  text-decoration: none;
  border-bottom: 2px solid var(--color-secondary);
}

.tab:hover {
  text-decoration: none;
  border-bottom: 2px solid var(--color-secondary);
  filter: brightness(1.1);
}

.activeTab {
  border-bottom: 2px solid var(--color-secondary);
}

.searchResults {
  max-width: 100%;
}
