@use "../../../theme/vars.scss";

.grouped {
  background-color: var(--color-primary);
  border-bottom-color: var(--color-primary);
  td {
    border-bottom-color: var(--color-primary);
    color: var(--color-primary-contrast);
  }
}
.tableContainer {
  overflow-x: visible;
}

.container {
  width: 100%;
  margin: auto;
  margin-top: 10px;
  max-width: #{"min(calc(100% - 20px), 1400px)"};
  height: calc(100vh - 50px - 20px);
}

.content {
  height: 100%;
}

.hierarchyContainer {
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tree {
  overflow: auto;
}

.noContentMsg {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.propertyTableContainer {
  width: max-content;
  flex-shrink: 0;

  .propertyRow {
    &:nth-of-type(odd) {
      background-color: #f9f9f9;
    }

    .propertyDescriptionCell {
      max-width: 300px;
      overflow: auto;
    }
  }
}

.addClassButton {
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
}

.classPaper {
  height: 100%;
}

.classContainer {
  height: 100%;
  overflow: auto;
}

.breadcrumbs {
  font-size: smaller;
  margin: -5px;
}
.block {
  background: #{"hsl(from var(--color-primary) h s l / 0.1)"};
}
.instancesGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .instanceItem {
    display: flex;
    justify-content: space-between;
    word-break: break-word;
    padding: 0.5rem;
    gap: 1rem;
    &:nth-child(odd) {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: 0;
      padding-right: 1.5rem;
    }
    &:nth-child(even) {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: 1.5rem;
      padding-right: 0;
    }
  }
}

.grabable {
  cursor: grab;
}
