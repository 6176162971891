@use "../../theme/vars.scss";
$height: 0.8 * vars.$navbar-height;
.wrapper {
  align-items: center;
  height: 50%;
  &:focus-visible {
    // navIcon is focused but the div wrapper for the svg is outlined - looks cleaner
    outline: none;
    div div {
      outline: vars.$focus-border;
    }
  }
  .navIcon {
    height: $height;
    width: $height;
    margin-right: 15px; //some spacing between logo and text
  }
}
