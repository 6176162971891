.htmlView {
  border-left: 4px solid var(--color-primary);
  padding-left: 1rem;
}

.multiline {
  white-space: pre-wrap;
}

.extraProperties {
  background-color: #{"hsl(from var(--color-primary) h s l / 0.1)"};
}
