@use "../../theme/vars.scss";

.headerRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  a:global(.MuiButton-root) {
    &:hover,
    &:focus {
      color: white;
    }
  }
}

.grow {
  flex-grow: 1;
}

.footer {
  td {
    border-bottom: 0;
  }
}

.actions {
  white-space: nowrap;
  text-align: right;
}

.noContentMsg {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.infoIcon {
  margin-bottom: 1px;
}

.typeOfError {
  margin-top: 10px;
  font-weight: bold;
}
