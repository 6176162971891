@use "../../theme/vars.scss";

.box {
  padding: 10px 15px;

  .dsTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.avatar {
  margin: 4px 0px;
  a {
    color: var(--color-gray);
  }
}
.colAvatar {
  padding-right: 20px;
}

.descriptionWrapper {
  display: flex;
}

.colDescription {
  flex-grow: 1;
  overflow: hidden;
}
//Make rule more specific, so we're overwriting the default markdown styling
div.description {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 0; //override browser default. padding is done by parent already, and having browser-defaulted padding
  //will messup vertical central alignment
  max-height: 180px;
}
.topics {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
}
.colIcons {
  font-size: 130%;
  margin-right: 10px;
  margin-left: 10px;
}
