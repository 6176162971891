@use "../../theme/vars.scss";
.alert {
  display: flex;
  .icon {
    margin-left: 15px;
    width: 15px;
    display: flex;
    align-items: center;
  }
  &.sm {
    margin: 0;
    .content {
      padding: 5px 15px;
    }
  }
  transition: box-shadow 0.25s;
  border-radius: 2px;
  .content {
    padding: 11px;
    width: 100%;
  }
  color: white;
}
.error {
  &.transparent {
    background-color: transparent;
    color: var(--color-error);
    border: 1px solid var(--color-error);
  }
  background-color: var(--color-error);
}
.warning {
  &.transparent {
    background-color: transparent;
    color: var(--color-warning);
    border: 1px solid var(--color-warning);
  }
  &:not(.transparent) a {
    color: white;
    text-decoration: underline;
    &:hover {
      text-shadow: 1px 0 0;
    }
  }
  background-color: var(--color-warning);
}
.info {
  &.transparent {
    background-color: transparent;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    a {
      text-decoration: underline;
    }
  }
  background-color: var(--color-primary);
}

.success {
  &.transparent {
    background-color: transparent;
    color: var(--color-success);
    border: 1px solid var(--color-success);
  }
  background-color: var(--color-success);
}
