.prefixTooltip {
  background-color: white;
  color: inherit;
  border: 1px #eee solid;
  max-width: 500px;
  word-break: break-word;
}

.menuLink:hover {
  // MenuItems already show interactivity with hover
  color: inherit;
}
