@use "../../../../../theme/zIndexes.scss";

.networkContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .backdrop {
    position: absolute;
    font-size: 3rem;
    color: #fff;
    z-index: zIndexes.$ide-backdrop-z;
  }

  .backdropShow {
    opacity: 1 !important; //Need to provide important to ensure MUI backdrop opacity is overidden
  }

  .backdropHide {
    opacity: 0 !important; //Need to provide important to ensure MUI backdrop opacity is overidden
  }
}

.canvasContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.controls {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1rem;
  z-index: zIndexes.$ide-backdrop-z - 1;
  > button {
    width: 35px;
    height: 35px;
    margin-top: 10px;
  }
}
