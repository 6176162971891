@use "../../theme/zIndexes.scss";
@use "../../theme/vars.scss";

.container {
  position: relative;
  display: flex;
}

.topic {
  position: absolute;
  top: 3px;
  left: 3px;
  margin-top: 0;
  svg {
    margin-right: -2px;
  }
}

.topicSelected {
  margin-top: 6px;
  margin-left: 6px;
}

.searchFieldInput {
  padding-right: 54px;
  padding-top: 9px;
  padding-bottom: 9px;
  flex-grow: 1;
  box-sizing: border-box;
  outline: none;
  border: 1px solid var(--color-gray-lighter);
  border-radius: 6px;
  transition: border 0.2s ease-in-out;
  font-weight: normal;
  text-overflow: ellipsis;
  //IE11 hack for font size of searchfield
  @media all and (-ms-high-contrast: none) {
    font-size: 1.2em;
  }
  &:focus-visible {
    // needed for main page searchbar with topic button
    outline: vars.$focus-border;
  }
}

.search {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  min-width: 35px;
}

.searching {
  animation: circle 1s linear infinite;
}

@keyframes circle {
  from {
    transform: rotate(0deg) translateX(1px) rotate(0deg);
  }
  to {
    transform: rotate(-360deg) translateX(1px) rotate(360deg);
  }
}

.topics {
  position: absolute;
  top: 44px;
  background-color: white;
  border: 1px solid var(--color-gray-lighter);
  min-width: 200px;
  z-index: zIndexes.$autosuggest-suggestions-container-z;
}
