@use "../../../theme/vars.scss";

.container {
  display: flex;
  align-items: center;
  width: 100%;
}

.buttoncontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.values {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
  > div + div {
    margin-top: 15px;
  }
}

.label {
  margin-left: 1rem;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}
.inLabel:first-letter {
  text-transform: capitalize;
}
.imageWidgets {
  flex-wrap: wrap;
}

.remainingWidgets {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0.3rem;

  .remainingWidget {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus-visible {
      // this re-arranges the order of the links for some reason?
      outline: none;
      border: vars.$focus-border;
    }
  }
}
