.codeMirror {
  margin-top: 0;
  border: 1px solid #d1d1d1;
  :global(.cm-editor) {
    max-height: 500px;
  }
}
.cmError {
  text-decoration: underline wavy var(--color-error);
}
.frameTitle {
  margin-top: 0;
  margin-bottom: 0;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
}
.executeQueryButton {
  padding: 6px 10px;
  min-width: unset;
  .playIcon {
    height: 1.5rem;
    margin-left: 0;
    margin-right: 0;
  }
}
.squareCorners {
  border-radius: 0;
}
