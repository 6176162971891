@use "../../theme/vars.scss";

.card {
  max-width: 280px;
  width: 0;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 2px;
  min-width: 200px;
}
.limitContainer {
  justify-content: space-evenly;
}
.cardHeaderWithHint {
  justify-content: center;
}
.cardHeader {
  text-align: center;
}
