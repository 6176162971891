@use "../../theme/vars.scss";

.header {
  vertical-align: baseline;
  &.sorting {
    cursor: pointer;
    &:hover {
      background-color: var(--color-gray-lighter);
    }
    > div {
      justify-content: space-between;
    }
  }
}
.slider {
  margin-top: 6px;
}
.filterCell {
  padding: 3px 16px;
}
.space {
  flex-grow: 1;
}
.cell {
  word-break: break-word;
  max-width: 250px;
}
.subComponentCell {
  padding-bottom: 0;
  padding-top: 0;
}
.noBorderBottom {
  border-bottom: unset;
}
.noContentCell {
  padding: 2rem;
}
.paginationOptions {
  display: flex;
}

.tablePaper {
  width: fit-content;
  max-width: calc(100% - 20px);
  margin: auto;
}
.tableContainer {
  background: white;
}

.alignEnd {
  align-items: end;
}
.actionButtons {
  white-space: nowrap;
}
