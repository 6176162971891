.licenseFormEl {
  display: flex;
  min-width: 300px;
}
.licenseLink {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.dragging {
  cursor: ns-resize;
  background-color: var(--color-gray-lighter);

  button {
    cursor: ns-resize;
  }
}
