@use "../../theme/vars.scss";

.key {
  border-radius: 2px;
  border: 1px solid var(--color-gray-light);
  padding: 0 3px;
  margin: 0.5px;
  background-color: var(--color-gray-lighter);
  box-shadow: vars.$shadow;
}
