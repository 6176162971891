.header {
  word-break: break-word;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  ~ div {
    margin-top: 10px;
  }
}

.primaryInfo {
  margin: 0px 10px 0px 20px;
  flex-grow: 1;
}

.title {
  align-items: center;

  > h2 {
    margin: 0;
  }

  > i {
    margin-left: 15px;
    margin-top: 5px;
  }
}

.statements {
  font-size: 95%;
}

.importDemo {
  align-self: flex-start;
  margin-top: 10px;
}
.description {
  max-height: 180px;
  overflow: hidden;
  &:empty {
    display: none;
  }
  position: relative;
  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 50%, white 95%);
    pointer-events: none;
  }
}
.exampleResources {
  display: flex;
  flex-wrap: wrap;

  margin: -5px;
  > * {
    margin: 5px;
  }
}

.markdown {
  overflow: hidden;
}
