$labelLineHeight: 20;
//contrary to to computed lined height of  bootstrap sass file, which is 22
//this value should be correct, to avoid offsetting input icons

.form {
  label {
    font-weight: normal;

    width: 100%;
  }

  input {
    margin-top: 5px;
    width: 100%;
  }
}
