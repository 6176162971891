@use "../../theme/vars.scss";

.fileList {
  width: auto;
  border: none;
}

.externalLink {
  text-align: center;
}
.btns {
  padding-top: 9px;
  justify-content: flex-end;
  display: flex;
  > * {
    margin: 0 5px;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  .heading {
    align-self: center;
  }
  .line {
    align-self: center;
    flex-grow: 1;
  }
  .cancel {
    flex-grow: 0;
  }
  .next {
    flex-grow: 0;
  }
}
.padFiles {
  padding: 4px 0px 0px 0px;
}
.uploads {
  display: flex;
  align-items: center;
  margin-top: 6px;

  .header {
    flex-grow: 1;
  }
  .stopUpload {
    padding: 3px;
    margin: 0px 4px;
    cursor: pointer;

    &:hover {
      color: var(--color-warning);
    }
  }
}

.numEnqueuedUploads {
  padding-top: 15px;
  padding-left: 4px;
  padding-bottom: 9px;
}
