@use "../../theme/vars.scss";

.progressBar {
  width: 95%;
  height: 6px;
  margin-top: 8px;
  margin-bottom: 10px;
}

.pointer {
  cursor: pointer;
}

.item {
  margin-top: 1em;
  padding: 14px;
  border-radius: 2px;
  box-shadow: vars.$shadow;
  background-color: white;
  display: flex;
  flex-direction: column;

  dt {
    font-weight: normal;
  }
}

.topRow {
  display: flex;
  justify-content: space-between;
  p {
    margin: 0 0 10.5px;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bigXContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.statusInfo {
  padding: 4px;
}
