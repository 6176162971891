@use "../../theme/vars.scss";

.outerSearchContainer {
  display: flex;
  justify-content: center;
}

.innerSearchContainer {
  flex-grow: 1;
  max-width: 500px;
}

.browseButtons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 22px 44px;
  margin: 20px;

  .browseButton {
    color: #fff;
  }
}

.exampleList {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.example {
  box-shadow: vars.$shadow;
  background-color: white;
  border-radius: 4px;
  overflow-wrap: break-word;
}
@media (max-width: vars.$screen-xs-max) {
  .example {
    word-break: break-word;
  }
}
.yourStuff {
  min-width: 280px;
  width: 280px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &:empty {
    display: none;
  }
}

.createOrg {
  width: 92px;
  white-space: normal;
  margin-top: 0;
}

.itemTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.content {
  gap: 10px;

  @media (max-width: vars.$screen-xs-max) {
    flex-direction: column-reverse;
    margin: 0;
    width: 100%;

    .yourStuff {
      width: 100vw;
      margin: 0;
    }
  }
}

.listItem:hover,
.listItem:focus-visible {
  background-color: rgba(0, 0, 0, 0.06);
}

.banner {
  padding-top: 55px;
  position: relative;

  &.customBanner {
    min-height: 500px;

    .backgroundImage img {
      filter: brightness(60%);
    }
  }

  .backgroundImage {
    background:
      url("./circles.svg") bottom center no-repeat,
      linear-gradient(60deg, var(--color-banner), transparent 300%);
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &.tbdBanner {
    .tbdWrapper {
      display: flex;
      position: relative;
      max-height: 80vh;

      .tbdContent {
        flex-basis: 1200px;
        overflow: hidden;

        .tbdTextContent {
          flex-grow: 1;
          flex-basis: 300px;
          flex-direction: column;
          color: white;

          .tbdTitle {
            color: inherit;
            font-size: 1.1rem;

            h1 {
              color: inherit;
              font-size: 3rem;
            }
          }
        }

        .tbdImage {
          flex-grow: 1;
          flex-basis: 200px;
          align-self: center;
        }

        @media (max-width: vars.$screen-sm-min) {
          .tbdImage {
            display: none;
          }
        }

        .sideImage {
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  .wrapper {
    position: relative;
    width: 100%;

    .welcomeMessage {
      padding: 1em;
      color: white;
      font-size: 1.35rem;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: white;

        &:first-child {
          margin-top: 1.5rem;
          text-align: center;
        }
      }

      a {
        color: white;
        text-decoration: underline;

        &:hover {
          text-shadow: 1px 0 0; // Bolder hack, this way the "physical" size of the text doesn't change
        }
      }
    }
  }
}

.flippedIcon {
  transform: rotate(90deg) scaleX(-1);
}

.searchBar {
  width: 100%;

  input {
    scroll-margin-top: 72px;
  }
}

.masonryWrapper {
  max-width: 100%;
  overflow: hidden;
}

.masonry {
  width: calc(100% + 16px);
  overflow: hidden;
}

.primaryButton {
  color: var(--color-primary);
  background-color: white;

  &:hover {
    background-color: var(--color-gray-lighter);
  }

  &:focus-visible {
    border: vars.$focus-border;
  }
}

.secondaryButton {
  color: white;
  background-color: var(--color-secondary);

  &:hover {
    background-color: var(--color-secondary);
    filter: brightness(1.1);
  }

  &:focus-visible {
    border: vars.$focus-border;
  }
}
