@use "../../theme/vars.scss";
.icon {
  background-color: var(--color-primary);
  display: inline-block;
  font-size: 2em;
  width: 3em;
  min-width: 3em;
  height: 3em;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  &.sm {
    width: 1em;
    min-width: 1em;
    height: 1em;
    .faIcon {
      font-size: 0.6em;
    }
  }
  &.md {
    width: 2em;
    min-width: 2em;
    height: 2em;
    .faIcon {
      font-size: 1.2em;
    }
  }
  .faIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
    color: var(--color-primary-contrast);
  }
}
