@use "../../theme/vars.scss";
.header {
  background-color: var(--color-banner);
  padding: 8px 20px 0px 8px;
  color: var(--color-primary-contrast);
  align-items: center; //vertical
}
.headerTitle {
  color: var(--color-primary-contrast);
  font-size: 120%;
  &:hover,
  &:focus {
    color: var(--color-primary-contrast);
    text-decoration: none;
  }
}

.tabs {
  $borderHeight: 2px;
  $horizontalSpacing: 30px;
  $verticalSpacing: 8px;
  flex: 99999 1 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  li {
    list-style: none;
  }

  .tabBtn {
    &.activeBtn {
      background-color: transparent;
      color: var(--color-primary-contrast);
      opacity: 1;
      box-shadow: none;
      border-bottom: $borderHeight solid var(--color-secondary);
    }
    display: block;
    padding: $verticalSpacing $horizontalSpacing ($verticalSpacing - $borderHeight) $horizontalSpacing;
    text-transform: uppercase;
    color: rgba(var(--color-primary-contrast), 0.8);
    background-color: transparent;
    border-bottom: $borderHeight solid transparent;
    &:hover {
      text-decoration: none;
      color: var(--color-primary-contrast);
      background-color: var(--color-banner);
      filter: brightness(0.9);
    }
    &:focus {
      text-decoration: none;
      color: var(--color-primary-contrast);
    }
  }
}
