@use "../../../theme/vars.scss";

.accounts {
  display: flex;
  flex-wrap: wrap;
}

.account {
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .accountName {
    text-align: center;
    word-break: break-all;
    word-wrap: break-word;
    max-width: 80px;
  }
}

.datasetButton:focus {
  outline: none;
}
.datasetButton:focus-visible {
  border: vars.$focus-border;
}
