@use "../../theme/vars.scss";

.container {
  display: flex;
  padding: 12px 15px 15px 19px;
}
.main {
  flex-grow: 1;
  word-break: break-word;
}
.icons {
  display: flex;
  align-items: center;
  font-size: 130%;
  margin-left: 10px;
  margin-right: 10px;
}
.queryTypeIcon {
  font-size: 13px;
  margin: 6px 25px 0px 6px;
}
// flip an icon over the bottom left corner
.flipped {
  transform: rotate(90deg) scaleX(-1);
}
