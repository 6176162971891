.list {
  margin: 0;
  display: block;
}

.keyBind {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: var(--color-gray-lighter);
  }
}
