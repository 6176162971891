@use "../../../theme/vars.scss";
@use "../../../containers/NavConsole/vars.scss" as vars2;

$avatarWidth: 44px;

.text span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.item {
  color: var(--color-gray);
  text-decoration: none;
  text-wrap: nowrap;
  height: 46px;
  &:global(.MuiListItemButton-dense) {
    height: 35px;
  }
  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
    filter: none;
  }
  &.active,
  &:global(.Mui-selected) {
    color: var(--color-secondary);
    background-color: transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  &:visited {
    text-decoration: none;
  }
  .beta {
    font-size: 0.55rem;
    margin-left: 6px;
    font-weight: bold;
    line-height: normal;
  }
}
.numberBadge > span {
  bottom: -3px;
  right: -1px;
}

.collapsedAvatar {
  min-width: $avatarWidth;
  align-self: center;
}

.number {
  font-size: 12px;
  font-weight: bold;
}

.warning {
  color: var(--color-warning);
}
