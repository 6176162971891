@use "../../../theme/vars.scss";

.fileDropZone {
  max-height: 300px;
  text-align: center;

  width: 100%;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  background: white;
  border: 3px dashed var(--color-gray-lighter);
  transition:
    color 0.4s,
    background-color 0.4s,
    opacity 0.4s,
    border-color 0.4s;

  &.hover {
    color: var(--color-primary);
    border: 3px dashed var(--color-primary);
  }

  &:hover {
    color: var(--color-primary);
    border: 3px dashed var(--color-primary);
    cursor: pointer;
  }
  &.dragActive {
    color: var(--color-primary);
    border: 3px dashed var(--color-primary);
  }
  &:active:hover {
    background: var(--color-gray-lighter);
    filter: brightness(1.1);
  }
  &:focus-visible {
    outline: vars.$focus-border-blue;
  }

  .icon {
    font-size: 9em;
  }
}

.wordWrapper {
  width: fit-content;
  color: #666;
  text-transform: uppercase;
  position: relative;
  font:
    700 16px arial,
    sans-serif;
  left: 44%;
  padding: 30px;
  letter-spacing: 1px;
}
