@use "../../../../../theme/zIndexes.scss";
div.visContainer {
  height: 100%;
  position: relative;
  overflow: hidden;
  font: inherit;
  .loadingElement {
    position: absolute;
    top: 1rem;
    left: 3rem;
  }
  .mapContainer {
    height: 100%;
  }
  .settingsButton {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 1rem;
    right: 1rem;
  }
  .drawerElement {
    position: absolute;
  }

  .backdrop {
    position: absolute;
    font-size: 3rem;
    color: #fff;
    z-index: zIndexes.$ide-backdrop-z;
  }

  .backdropShow {
    opacity: 1 !important; //Need to provide important to ensure MUI backdrop opacity is overidden
  }

  .backdropHide {
    opacity: 0 !important; //Need to provide important to ensure MUI backdrop opacity is overidden
  }
  :global(.maplibregl-canvas-container.maplibregl-interactive.maplibregl-track-pointer) {
    cursor: default;
  }
  :global(.maplibregl-cooperative-gesture-screen) {
    z-index: zIndexes.$ide-backdrop-z;
  }
}
.sortableList {
  background-color: var(--color-gray-lighter);
  border-radius: 4px;
  user-select: none;
}
.tileListItem {
  cursor: grab;
  border-left: 2px solid transparent;
  &:hover {
    border-left-color: var(--color-primary);
  }
  &.dragging {
    border-left-color: var(--color-primary);
    cursor: ns-resize;
    :global(.MuiButtonBase-root) {
      cursor: ns-resize;
    }
  }
}

.warnIcon {
  color: var(--color-warning);
}
.popupAnchor {
  position: absolute;
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  z-index: zIndexes.$ide-backdrop-z - 2;
  overflow: visible;
}
.parseReport {
  position: absolute;
  left: 3rem;
  right: 3rem;
  bottom: 2rem;
  max-height: calc(100% - 4rem);
  color: inherit;
  background-color: white;
}
.popupContent {
  left: -300px;
  position: absolute;
  overflow: auto;
  .popupContainer {
    width: 300px;
  }
}

//Excluding Geo controls from print preview
@media print {
  :global(.maplibregl-ctrl) {
    display: none;
  }
}

.removePositionButton {
  padding: unset;
  span {
    margin: unset;
  }
}
