@use "../../theme/vars.scss";

div.flex {
  display: flex;
  flex-wrap: wrap;
  margin: -20px;

  & > * {
    flex-grow: 1;
    margin: 20px;
  }
}

.consent {
  background-color: var(--color-warning);
  color: white;
  a {
    color: white;
    text-decoration: underline;

    &:hover {
      text-shadow: 1px 0 0;
    }
  }
  h3 {
    color: white;
  }
}
