@use "../../theme/vars.scss";

.container {
  flex-grow: 1000000;
  flex-basis: 0px; // Flex-basis IE11 doesn't handle flex-basis auto too well

  @media (min-width: vars.$screen-lg-min) {
    flex-basis: vars.$screen-lg-min;
    flex-grow: 0;
  }
}

.flex {
  display: flex;
}

.side {
  flex-grow: 1;
  flex-basis: 40px;
  @media (max-width: vars.$screen-sm-min) {
    flex-basis: 0px;
  }
}
