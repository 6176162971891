@use "../../theme/vars.scss";
.innerContainer {
  display: flex;
  flex-direction: column;
  > span {
    height: 0px;
  }
}

.key {
  background-color: var(--color-gray-lighter);
  color: var(--color-gray-dark);
  input {
    text-align: center;
  }
}

.keyPointer {
  :hover {
    cursor: pointer;
  }
}

.link {
  font-size: 1.6rem;
  position: relative;
  top: 1rem;
  right: 5rem;
  z-index: 10;
  &:hover {
    color: var(--color-gray-dark);
  }
}

.icon {
  margin-right: 10px;
}
