@use "index.scss";
@use "../../theme/vars";

.addItemSegment {
  @extend .constrainWidth;

  transition: opacity 0.5s;
  &.hidden {
    opacity: 0;
  }

  > button {
    background-color: transparent;
    color: var(--color-gray-light);
    border: 1px var(--color-gray-light) dashed;
    width: 100%;
    &:hover,
    &:active,
    &:focus,
    &:active:hover {
      background-color: transparent;
      border-color: var(--color-gray);
      color: var(--color-gray);
    }
    &:focus-visible {
      outline: vars.$focus-border;
      border: none;
    }
  }
}
