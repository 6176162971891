@use "../../theme/vars.scss";
.placeHolder {
  margin: 0;
  width: 100%;
  height: 200px;
}
.widget {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
.widgetMedia {
  max-width: 200px;
  max-height: 100%;
}
.widgetContent {
  flex-grow: 1;
  margin-left: 1rem;
}
.widgetComment {
  max-height: 100px;
  overflow: hidden;
}
.classWidget {
  margin: 0.5rem 0.25rem;
  background: var(--color-primary);
  color: var(--color-primary-contrast);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  &:hover,
  &:active {
    color: var(--color-primary-contrast);
  }
}
.widgetTitle {
  word-break: break-word;
}
