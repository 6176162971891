@use "../../theme/vars.scss";

div.alert {
  margin: 15px 15px 50px 15px;
}
.logos {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @media (max-width: vars.$screen-xs-max) {
    zoom: 0.8;
  }
  .logo > svg {
    height: 200px;
    width: 200px;
  }

  .index {
    .logo > svg {
      margin-top: 28px;
      margin-bottom: 21px;
      height: 151px;
    }
  }
  .statusMsg {
    font-size: 90%;
    text-align: center;
    height: 42px;
  }
}
.logo {
  display: flex;
  flex-direction: column;
}
.label {
  font-size: 160%;
  text-align: center;
  flex-grow: 1;
  svg {
    font-size: 80%;
  }
}
.codemirror :global(.CodeMirror) {
  height: auto;
}
.btncontainer {
  display: flex;
  justify-content: flex-end;
  & > * {
    margin: 0 5px;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  .heading {
    align-self: center;
  }
  .line {
    align-self: center;
    flex-grow: 1;
  }
}

.fileDropZone {
  max-height: 300px;
  text-align: center;

  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  background: white;
  border: 3px dashed var(--color-gray-lighter);
  transition:
    color 0.4s,
    background-color 0.4s,
    opacity 0.4s,
    border-color 0.4s;

  &.hover {
    color: var(--color-primary);
    border: 3px dashed var(--color-primary);
  }

  &:hover {
    color: var(--color-primary);
    border: 3px dashed var(--color-primary);
    cursor: pointer;
  }
  &.dragActive {
    color: var(--color-primary);
    border: 3px dashed var(--color-primary);
  }
  &:active:hover {
    background: var(--color-gray-lighter);
    filter: brightness(1.1);
  }

  .icon {
    font-size: 9em;
  }
}

.downloadProgressBarHeadingGrey {
  display: flex;
  align-items: center;
  color: lightgrey;
  margin-right: 32px;
  .icons {
    margin-left: 4px;
  }
}

.downloadProgressBarHeading {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-left: 8;
  margin-right: 60px;
  .icons {
    margin-left: 4px;
  }
}

.progressBarAndText {
  margin-top: 2px;
  display: flex;
  align-items: center;
  cursor: progress;
  .progressBar {
    position: relative;
    width: 100%;
    margin-right: 8px;
    > div {
      height: 8px;
    }
    .shimmer {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      background: white;
      background: radial-gradient(circle, rgba(255, 255, 255, 0.733) 0%, rgba(148, 187, 233, 0) 100%);
      background-repeat: no-repeat;
      background-size: 10rem 100%;

      animation-name: shimmer;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    @keyframes shimmer {
      0% {
        background-position: left -10em top;
      }

      100% {
        background-position: right -10em top;
      }
    }
  }
  .text {
    display: flex;
    min-width: 30px;
    justify-content: flex-end;
    .greyText {
      color: lightgrey;
    }
  }
}
