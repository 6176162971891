@use "../../theme/vars.scss";
@use "../../theme/zIndexes.scss";

.noDataMsg {
  justify-content: center;
  margin-top: 20px;
}

.tableWrapper {
  display: table;
  margin: 0px auto;
  padding: 20px;
  padding-bottom: 1px; //adjust for padding already at bottom of table element
  background-color: white;
  td {
    > div {
      max-height: 200px;
      min-width: 200px;
      overflow: auto;
    }
  }
}
.row:hover {
  .rowHover {
    visibility: visible;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 21px;
  thead {
    label {
      font-weight: bold;
      margin: 4px;
    }
    th {
      padding: 8px 4px;
      > div {
        margin-top: 8px;
      }
    }
  }
  tbody {
    td {
      padding: 8px;
      vertical-align: top;
    }
    tr:hover {
      background-color: #f5f5f5;
    }
  }
}

.moreBtn {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.filterWrapper {
  display: flex;
  align-items: center;
  input {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    outline: none;
    border: 1px solid var(--color-gray-lighter);
    font-weight: normal;
    text-overflow: ellipsis;
    &:hover {
      border: 1px solid var(--color-gray-light);
    }
    &:focus {
      border: 1px solid var(--color-primary);
    }
    &:focus-visible {
      border: none;
      outline: vars.$focus-border;
    }
  }
  &.hasVal {
    .removeFilterIcon {
      visibility: visible;
    }
    input {
      padding-right: 35px; //make sure clear-btn does not overlap input txt
    }
  }
  .removeFilterIcon {
    position: relative;
    right: 15px;
    visibility: hidden;
    margin: 0px -10px;
  }
}

.filterSub .sub * {
  font-style: italic;
}
.filterPred .pred * {
  font-style: italic;
}
.filterObj .obj * {
  font-style: italic;
}
.filterGraph .graph * {
  font-style: italic;
}

.form {
  display: flex;
  flex-direction: column;
}

.quadEditorContainer {
  min-height: 300px;
  height: 100%;
}

.quadEditor {
  height: inherit;
  :global(.cm-editor) {
    height: inherit;
  }
}

:global {
  .react-autosuggest__container {
    width: 100%;
    position: relative;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 41px;
    font-size: 90%;
    font-weight: normal;
    border: 1px solid var(--color-gray-lighter);
    background-color: white;
    box-shadow: vars.$shadow;
    z-index: zIndexes.$autosuggest-suggestions-container-z;
    max-width: 200%;
    overflow: auto;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 2px 8px;
    white-space: nowrap;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: var(--color-secondary);
    color: var(--color-secondary-contrast);
  }
}
