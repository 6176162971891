//some variables used for console only
$console-navbar-height: 50px;

$font-size: 15px;

//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-base: 1.428571429 !default; // 20/14

$shadow:
  0 1px 3px 0 rgba(0, 0, 0, 0.1),
  0 1px 1px 0 rgba(0, 0, 0, 0.07),
  0 2px 1px -1px rgba(0, 0, 0, 0.06);

$navbar-height: 50px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xxs-max: ($screen-xs-min - 1);
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

// Accessibility
$focus-border: 2px solid var(--color-secondary);
$focus-border-blue: 2px solid var(--color-primary);
