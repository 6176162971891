@use "../../theme/vars.scss";

.container {
  display: flex;
  align-items: center;
  background-color: white;
  overflow-y: hidden;
  overflow-x: auto;
}

.resourceAndProperty {
  display: flex;
  align-items: center;
}

.property {
  margin: 0px 33px;
  padding: 3px 24px;
  display: flex;
  align-items: center;
}

.propertyName {
  padding: 5px;
}

.currentResource {
  border-bottom: 2px solid var(--color-primary);
  padding: 10px;
}

.lineWrapper {
  //remove arrow-part of icon
  width: 10px;
  overflow: hidden;
}
