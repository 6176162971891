.colorPickerCtr {
  width: fit-content;
  height: fit-content;
}

.colorIndicator {
  width: 100%;
  height: 10px;
}

.sketchPicker {
  padding-top: 5px;
  width: fit-content;
  height: fit-content;
  z-index: 2;
}
