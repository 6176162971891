@use "../../theme/vars.scss";

.notification {
  font-size: vars.$font-size;
}
.linkAction {
  font-size: vars.$font-size;
  color: white;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: white;
    font-weight: bolder;
  }
}
