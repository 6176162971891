@use "../../theme/vars.scss";

.dots {
  cursor: pointer;
  font-weight: bold;
  color: var(--color-link);
  &:hover {
    filter: brightness(1.1);
    text-decoration: none;
  }
}
