@use "../../theme/vars.scss";

.circle {
  background-color: var(--color-gray-lighter);
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 1px -1px rgba(0, 0, 0, 0.12);
  height: 15px;
  width: 15px;
  min-height: 15px;
  min-width: 15px;

  border-radius: 50%;
  &.red {
    background-color: var(--color-error);
  }
  &.green {
    background-color: var(--color-success);
  }
  &.orange {
    background-color: var(--color-warning);
  }
  &.flashGreen {
    animation: flashGreen 1s ease-in-out infinite alternate;
  }
  &.flashOrange {
    animation: flashOrange 1s ease-in-out infinite alternate;
  }
}

@keyframes flashGreen {
  from {
    background-color: var(--color-gray-lighter);
  }
  to {
    background-color: var(--color-success);
  }
}
@keyframes flashOrange {
  from {
    background-color: var(--color-gray-lighter);
  }
  to {
    background-color: var(--color-warning);
  }
}
