@use "../../theme/vars.scss";
@use "../../theme/zIndexes.scss";

$border-color: #b7b7b7;
$facet-background-color: #f8f8f8;

.background {
  background-color: #fff;
}

.container {
  max-width: vars.$screen-lg-min;
  display: flex;
  gap: 30px;
  margin: 0 auto 0 auto;
  padding: 30px 20px 60px 20px;

  @media (max-width: vars.$screen-xs-max) {
    padding: 30px 0 60px 0;
  }
}

.radioLabel {
  padding: 0.25rem 0.45rem;
  margin-right: 0;

  :global(.MuiFormControlLabel-label) {
    display: flex;
    flex-grow: 1;
  }
}

.smaller {
  font-size: 0.9rem;
}

.chip {
  max-width: 100%;
}

.filterDrawer {
  :global(.MuiDrawer-paper) {
    margin-top: vars.$navbar-height;
    max-height: calc(100vh - #{vars.$navbar-height});
    background-color: $facet-background-color;
  }
  @media (min-width: vars.$screen-md-min) {
    display: none;
  }
}

div.fixedFacets {
  @media (max-width: vars.$screen-sm-max) {
    display: none;
  }
}

div.modalFacets {
  justify-content: center;
  margin: 0 auto;
}

.fixedFacets,
.modalFacets {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 280px;
  min-width: 280px;

  background-color: $facet-background-color;
  padding: 10px;
  padding-bottom: 20px;

  .facetRows {
    max-height: 300px;
    overflow-y: auto;
  }

  .title {
    font-weight: bold;
  }

  .title,
  .facetRow,
  .showAll {
    padding: 0.25rem 0.45rem;
  }

  .facetRow {
    cursor: pointer;
    gap: 10px;

    .checkbox {
      padding: 0;
    }

    &:hover,
    &:focus-visible {
      outline: none;
      opacity: 1;
      background-color: var(--color-gray-lighter);
    }
    &.active {
      background-color: var(--color-primary);
      color: #fff;
      .checkbox {
        color: $facet-background-color;
      }
      &:hover {
        filter: brightness(0.9);
      }
      &:focus-visible {
        filter: brightness(0.9);
        outline: vars.$focus-border;
      }
    }
    &.inactive {
      opacity: 0.6;
    }
  }

  .statements {
    margin-left: 11px;

    .bars {
      margin-top: 15px;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: flex-end;
      gap: 2px;

      > div {
        min-width: 25.75px;
        background-color: var(--color-primary);
        cursor: pointer;
        opacity: 0.8;
        &.disabled {
          opacity: 0.38;
        }
        &:hover {
          opacity: 1;
        }
      }
    }

    .slider {
      margin-top: -3px;
      width: 220px;
    }
  }
}

.clearAll {
  font-weight: normal;
}

.currentFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

form.keywordField {
  border-radius: 4px;
}

.results {
  overflow: hidden;
}

.sortSelect {
  margin-top: 1px;
  &:before {
    border-bottom: 0;
  }
}

.filterButton {
  @media (min-width: vars.$screen-md-min) {
    display: none;
  }
}

.loading {
  height: 200px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.listHeader {
  justify-content: end;
  flex-direction: row-reverse;
  gap: 10px 20px;
}
.listItem {
  padding: 10px 15px;
  gap: 20px;

  &:nth-child(even) {
    background-color: $facet-background-color;
  }

  .hide1 {
    @media (max-width: 650px) {
      display: none;
    }
  }

  .hide2 {
    @media (max-width: 450px) {
      display: none;
    }
  }

  .ownerLink {
    color: var(--color-gray);
    filter: brightness(0.5);
  }
}
.avatar {
  margin: 4px 0px;
  a {
    color: var(--color-gray);
  }
}

.searchWidget {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  background-color: #fff;
  padding: 3px 5px 3px 8px;
  border-radius: 2px;
  border: 1px solid $border-color;

  &:focus-within {
    border: 1px solid var(--color-primary);
    box-shadow: 0 0 0 1px var(--color-primary);
    //box-shadow instead of outline for round edges
    .search {
      color: var(--color-primary);
    }
  }

  $animateDuration: 0.3s;
  .searchFieldInput {
    border: 0;
    outline: 0;
    width: 230px;
    min-width: 60px;
    flex-shrink: 1;
    opacity: 1;
    transition:
      width $animateDuration ease,
      opacity $animateDuration ease,
      margin-right $animateDuration ease,
      min-width $animateDuration ease;
    //IE11 hack for font size of searchfield
    @media all and (-ms-high-contrast: none) {
      font-size: 1.2em;
    }
  }
  .search {
    font-size: 18px;
    cursor: pointer;
    color: $border-color;
  }
}

.searching {
  animation: circle 1s linear infinite;
}

@keyframes circle {
  from {
    transform: rotate(0deg) translateX(2px) rotate(0deg);
  }
  to {
    transform: rotate(-360deg) translateX(2px) rotate(360deg);
  }
}

.details {
  > span:not(span:last-child) {
    &::after {
      content: " · ";
    }
  }
}
