@use "index.scss";
@use "../../theme/zIndexes";

.query {
  :global(.clickableIcon) {
    color: var(--color-gray-light);
  }
  &:hover :global(.clickableIcon) {
    color: var(--color-gray);
    &:hover {
      color: var(--color-secondary);
    }
  }

  .queryVariable {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .fields {
    flex-grow: 10;
  }
  .buttons {
    align-self: flex-end;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;

    .animatedRunButton {
      position: relative;
      overflow: hidden;
      z-index: 0;

      @keyframes rotate {
        100% {
          transform: rotate(1turn);
        }
      }

      &::before {
        content: "";
        position: absolute;
        z-index: -2;
        left: -40px;
        top: -65px;
        width: 160px;
        height: 160px;
        background-repeat: no-repeat;
        background-size: 50% 50%;
        background-position: 0 100%;
        background-image: linear-gradient(white, white);
        animation: rotate 2s linear infinite;
      }
      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 2px;
        top: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background: inherit;
      }
    }
  }
}

.queryOverlay {
  position: relative;
  .mask {
    z-index: zIndexes.$autosuggest-suggestions-container-z - 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    font-size: 10em;
    color: var(--color-secondary);
    background-color: #00000033;
  }
  &:hover {
    .mask {
      opacity: 1;
    }
  }

  .queryButtonWrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    > svg {
      margin: auto;
      max-height: 90%;
    }
  }
}

.cannotShowQuery {
  min-height: 200px;
  padding: 20px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    margin-right: 6px;
  }

  @media print {
    display: none;
  }
}

.ideLinks {
  margin-left: auto;
}
.links {
  display: flex;
  justify-content: flex-end;
  @extend .constrainWidth;
}
.visualization {
  @extend .constrainWidth;
  &.resultsWide {
    max-width: unset;
    padding: 0;
  }
  .resultsHeightSmall {
    height: 400px;
  }
  .resultsHeightDefault {
    height: 600px;
  }
  .resultsHeightLarge {
    height: 800px;
  }
}

.caption {
  text-align: center;
}

.warningIcon {
  color: var(--color-warning);
}

.placeholder {
  width: 100%;
  height: 500px;
  background: #eee;
}

.limitHeight {
  max-height: 600px;
  overflow-y: auto;
}

@media print {
  :global(.queryActions) {
    display: none;
  }

  :global(.noResultMessage) {
    display: none;
  }
  .limitHeight {
    max-height: unset;
    overflow-y: visible;
  }
  :global(.yasr .galleryWrapper) {
    display: block;
  }
  :global(.itemWrapper) {
    break-inside: avoid;
  }
}
.resultsResponse {
  border: 1px solid var(--color-gray-light);
  border-radius: 6px;
}
