.redirectLabel {
  flex-direction: column;
  align-self: center;
  margin-left: -1em;
  margin-right: 0.5em;
}

.dragHandle {
  cursor: ns-resize;
}
.match {
  margin-left: 1em;
  font-family: monospace;
}
