@use "../../../theme/vars.scss";

.container {
  width: 100%;
  min-height: 520px;
  overflow-x: hidden;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.noContentMsg {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.transitionGroup {
  width: 200%;
}

.searchField {
  :global {
    .react-autosuggest__suggestions-container--open {
      border: 1px solid var(--color-gray-light);
      max-width: 100%;
    }
  }
}

.searchFieldInput {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  outline: none;
  border: 1px solid var(--color-gray-light);
  transition: all 0.2s ease-in-out;
  font-weight: normal;
  text-overflow: ellipsis;
  &:focus {
    border: 1px solid var(--color-primary);
  }
}

:global {
  .forward {
    .resource-enter {
      transform: translateX(100%);
    }

    .resource-enter.resource-enter-active {
      transform: translateX(0);
      transition: transform 500ms;
    }

    .resource-exit {
      transform: translateX(-100%);
    }

    .resource-exit.resource-exit-active {
      transform: translateX(-200%);
      transition: transform 500ms;
    }
  }

  .backward {
    .resource-enter {
      transform: translateX(-100%);
    }

    .resource-enter.resource-enter-active {
      transform: translateX(0);
      transition: transform 500ms;
    }

    .resource-exit {
      transform: translateX(-100%);
    }

    .resource-exit.resource-exit-active {
      transform: translateX(0);
      transition: transform 500ms;
    }
  }
}
