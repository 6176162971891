@use "../../theme/vars.scss";
@use "../../theme/zIndexes.scss";

.warningsButton {
  :global(.MuiBadge-colorError) {
    background-color: var(--color-warning);
    color: var(--color-gray-dark);
    z-index: zIndexes.$second-navbar-z - 1; // FAB is at 1500
  }

  .contentWarning {
    background-color: var(--color-gray);
    color: var(--color-warning);
    margin-bottom: 1rem;
  }

  .icon {
    font-size: 1.3rem;
  }
}
