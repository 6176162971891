@use "../../../theme/vars.scss";

.noContentMsg {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.container {
  width: 100%;
}

.item {
  max-width: 100%;
}

.barChartItem {
  padding: 1px;
  > div {
    margin: 20px;
  }

  .showMore {
    margin-top: -10px;
  }
}

.square {
  padding: 10px;
  padding-top: 100%;
  height: 100%;
  > div {
    margin-top: -100%;
  }
  svg {
    border: 1px solid var(--color-gray-light);
  }
}

.appBar {
  background-color: white;
  color: inherit;
}

.tab {
  padding: 0;
}

.tabLink {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  > span {
    padding: 3px 10px;
    width: 86px;
  }
}

.hoverLabel {
  rect {
    fill: var(--color-gray-dark);
    stroke: white;
  }
  text {
    fill: white;
  }
}

.barChart {
  .classContainer {
    padding-left: 2px;

    &:hover {
      background-color: var(--color-gray-lighter);
      opacity: 1 !important;
    }

    .classRow {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 4px 2px;
      overflow: hidden;

      .bar {
        height: 21px;
        opacity: 0.8;
      }
    }

    .properties {
      overflow: hidden;
      background-color: white;

      .propertyRow {
        font-size: 80%;

        display: flex;
        align-items: center;
        padding: 0px 2px;
        overflow: hidden;

        .label {
          padding-left: 7px;
        }

        .bar {
          height: 8px;
        }

        opacity: 0.9;
        &:hover {
          opacity: 1;
        }
      }
    }

    .showMoreProperties {
      cursor: pointer;
      overflow: hidden;
      font-size: 80%;
      font-weight: normal;
      padding-left: 9px;
      background-color: white;
      display: flex;
      align-items: center;
      &:hover {
        color: var(--color-secondary);
      }
    }

    .label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      margin-right: 20px;

      min-width: 200px;
      max-width: 200px;
    }

    .barContainer {
      width: 100%;
    }
  }
}
