@use "../../theme/vars.scss";

.closed.canCreate {
  &:hover {
    .info {
      animation: animateInfo 0.25s ease 0.5s;
      animation-fill-mode: forwards;
      display: block;
    }

    border-bottom: 2px dashed var(--color-secondary);
  }
  border-bottom: 2px solid transparent;
  .hintWhenClosed {
    display: block;
  }
}

.hintWhenClosed,
.hintWhenOpen {
  display: none;
}
// .open {
.info {
  display: block;
  opacity: 1;
}

.input {
  input {
    border-width: 0px;
    border-bottom: 2px dashed var(--color-secondary);
    outline: none;
    height: 20px;
    width: 100px;
    background-color: transparent;
  }
  display: none;
}
.basename {
}
.open {
  .input {
    display: block;
  }
  .basename {
    display: none;
  }
  .hintWhenOpen {
    display: block;
  }
}

.info {
  position: absolute;
  transform: translateY(-100%);
  margin-top: -20px;
  margin-left: -3px;
  color: var(--color-secondary-contrast);
  background-color: var(--color-secondary);
  border-radius: 1px;
  padding: 1px 8px 1px;
  font-size: 12px;
  display: none;
  opacity: 0;
  .tip {
    font-size: 11px;
  }
}
.prefixable {
  display: inline-block;
}
@keyframes animateInfo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
