.label {
  font-size: 12px;
  margin-top: 5px;
}
.typeBadge {
  display: flex;
  justify-content: center;
  img {
    object-fit: fill;
    padding: 5px;
  }
  &.xs img {
    padding: 0;
  }
}
