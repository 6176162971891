@use "../../theme/vars.scss";

.resendPassword {
  font-size: small;
  font-style: italic;
  color: var(--color-primary);
  vertical-align: bottom;
  &:hover,
  &:active {
    color: var(--color-primary);
  }
  &:focus-visible {
    border: vars.$focus-border;
  }
}
