@use "../../../theme/vars.scss";

.title:first-letter {
  text-transform: capitalize;
}
.title {
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}

.values {
  overflow-x: auto;
  a:focus-visible {
    // the outline gets lost so I've added border instead
    border: vars.$focus-border;
    outline: none;
  }
}

.imageWidgets {
  overflow-x: auto;
}

.literal {
  :global(.literalInfo) {
    display: none;
  }
  :global(.literalHtml) {
    border-left: 4px solid var(--color-primary);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  span:focus-visible {
    outline: vars.$focus-border;
  }
}
