@use "../../theme/vars.scss";

$listItemHeight: 80px;

.item {
  margin: 14px 0px;
  border-radius: 2px;
  box-shadow: vars.$shadow;
  background-color: white;
  min-height: $listItemHeight;
}

.moreBtn,
.noContentMsg {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.moreBtn button {
  border-radius: 2px;
  box-shadow: vars.$shadow;
}
