@use "../../theme/vars.scss" as theme-vars;
@use "../../theme/zIndexes.scss";
@use "../NavConsole/vars.scss";

.header {
  background-color: var(--color-banner);
  padding: 10px 20px 0px 10px;
  color: white;
  align-items: center;
  flex-grow: 1;
  display: flex;
}
.headerTitle {
  color: white;
  font-size: 120%;
  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }
}

.homeButton {
  display: none;
  background-color: var(--color-banner);
  a {
    height: 50px;
    &:hover {
      background-color: var(--color-banner);
      filter: brightness(1.1);
    }

    div > span {
      color: white;
    }
  }
}

.nav {
  margin-top: theme-vars.$navbar-height;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: width vars.$animateDuration vars.$panelEase;
  z-index: zIndexes.$dataset-side-panel-z; /*need a z-index, because an item's tooltip has to be drawn over the main body's content*/
  overflow: hidden;
}

.main {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

@media #{vars.$showSmallPanelOnSize} {
  .toggler {
    display: none;
  }
  .homeButton {
    display: block;
  }
  .nav {
    width: vars.$panelWidthOpen;
    z-index: zIndexes.$dataset-side-collapsible-panel-z; //should be higher than rest of body, but less than navbar
  }
}
@media #{vars.$showLargePanelOnSize} {
  .nav {
    width: vars.$panelWidthOpen;
  }
  .homeButton {
    display: none;
  }

  .nav.collapsed {
    width: vars.$panelWidthCollapsed;
  }
}

.menuButton {
  color: white;
}

.toggler {
  border-top: 1px solid vars.$separatorColor;
  max-height: 40px;
  background-color: var(--color-gray-lighter);
  justify-content: center;
}

.content {
  flex-grow: 1;
}
