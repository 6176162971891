@use "../../theme/vars.scss";

.textField {
  margin-bottom: 4px;
  input {
    width: 100%;
    border-width: 0px;
    border-bottom: 1px solid var(--color-gray-lighter);
    outline: none;
    background-color: transparent;
  }
  &.err input {
    border-bottom: 2px solid var(--color-error);
  }

  .error {
    font-size: 12px;
    line-height: 12px;
    color: var(--color-error);
  }
}
