@use "../../theme/vars.scss";

.boolean {
  font-size: 120%;
}
.trueVal {
  color: var(--color-success);
}
.falseVal {
  color: var(--color-error);
}
