@use "../../theme/vars.scss";

.codemirror {
  margin-top: 1rem;
  max-width: 75vw;

  @media (min-width: vars.$screen-sm-min) {
    max-width: 700px;
  }
  @media (min-width: vars.$screen-md-min) {
    max-width: 900px;
  }
  @media (min-width: vars.$screen-lg-min) {
    max-width: 1100px;
  }
}
