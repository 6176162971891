@use "../../theme/vars.scss";

.muiOverride {
  min-width: 300px;
  max-width: 500px;
  flex-basis: 400px;
  flex-grow: 1;
}
.placeholder {
  color: var(--color-gray-light);
  option {
    color: var(--color-gray-dark);
  }
}
