@use "../../theme/vars.scss";

.widgetDescription {
  position: relative;
  max-height: 180px;
  padding: 1em;
  overflow: hidden;
  &:empty {
    display: none;
  }
  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 50%, white 95%);
    pointer-events: none;
  }
}

.storyWidget {
  background-color: white;
  display: flex;
  flex-direction: column;
  .header {
    position: relative;
    .bannerLink {
      color: inherit;
      font-size: 1.733em; // Style h2 as h3
    }

    .bannerLink:hover {
      text-decoration-color: white;
      text-decoration: underline;
    }
    .bannerLink:focus-visible {
      // removes outline from around the image of the widget
      outline: none;
    }

    .bannerImage {
      display: flex;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      background-color: #999;
      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        filter: brightness(60%);
      }
    }
    .bannerContent {
      display: block;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 1rem;
      word-break: break-word;
      &.customBanner {
        padding-top: 4rem;
        padding-bottom: 1rem;
        position: relative;
        color: white;
      }
    }
  }
  .widgetFooter {
    margin-top: auto;
    text-align: end;
  }
}
