@use "../../theme/vars.scss";
@use "../PlainTextField/style.scss";

.records {
  & > .record:nth-child(odd) {
    background-color: #f5f5f5;
  }
}
.record {
  display: flex;
  align-items: center;
}
.recordColumn {
  width: 25%;
  overflow: auto;
  white-space: nowrap;
}

.centered {
  margin: 1em auto;
  text-align: center;
}

.largeDialog {
  height: 90%;
  min-height: 90%;
  section {
    height: 100%;
  }
}

.cmContainer {
  height: calc(100% - 5.8em);
  border: solid #e6e6e6 1px;
  border-bottom: solid #e6e6e6 4px;
}

.codemirror {
  min-height: 100%;
  height: 100%;
  > * {
    height: 100%;
    min-height: 100%;
  }
}
