/**
    App Related
**/
$authentication-separator-line-z: 1; // needs z due to absolute positioning
$authentication-separator-box-z: 2; // needs to be on top of separator-line

// Yasgui leaflet (both console and yasgui) 100-1000
$ide-backdrop-z: 1001;
$geo-widget-caption-z: 1004; // Needs to at least as high as $leaflet-pane
$autosuggest-suggestions-container-z: 1010; // Needs an z-index due to absolute positioning, needs to be above leaflet
$dataset-side-panel-z: 1010; // Needs to be higher then content due to tooltips
$navbar-mask-z: 1040; // The mask should be higher then the content
$dataset-side-collapsible-panel-z: $navbar-mask-z + 1; //Needs to be higher then mask.
$yasqe-codemirror-hint-z: $dataset-side-collapsible-panel-z + 1; // Needs to go over Yasgui
$catalogue-query-mask: 1051; // Needs to override yasgui geo leaflet, (100-1000)
$catalogue-query-mask-icon: 1052; // Needs to override yasgui geo leaflet, (100-1000)
$navbar-container-z: 1250; // Needs to override yasgui geo (100-1000) and the query mask, also override Mui drawers (1200)

$second-navbar-z: $navbar-container-z - 1; // Needs to be under the primary navbar, but on top of all the content

// Modal z-index 1300
// MuiAutosuggest popper z-index 1400
$popper-z: 1500;

// the global loading bar should be on top of everything
$global-loading-bar-z: 2000;
