@use "../../../../../theme/vars.scss";

.chartContainer {
  --tooltip-offset: 0px;
  position: relative;
  overflow: visible;
  height: 100%;
}
body :global(.google-visualization-charteditor-dialog) {
  width: unset;
  height: unset;
  :global(.google-visualization-charteditor-thumbnail.jfk-radiobutton) {
    height: 76px;
    width: 118px;
  }
  :global(.goog-select) {
    height: 27px;
  }
  :global(.google-visualization-charteditor-preview-td) {
    width: 100%;
  }
}
:global(.google-visualization-table-table) {
  font-family: inherit !important;
}
:global(.google-visualization-tooltip) {
  margin-top: var(--tooltip-offset);
  z-index: inherit;
}
.chartBoundary {
  height: 100%;
  overflow: hidden;
  &.table {
    overflow: auto;
  }
}

.chartActionsContainer {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.chart {
  overflow: hidden;
  height: 100%;
  &.orgChart {
    overflow-x: auto;
  }
}

//Inheriting the fonts into gCharts
:global(.google-visualization-table-table) {
  font-family: inherit !important;
}

:global(g > text) {
  font-family: inherit !important;
}

@media print {
  table {
    word-break: break-all !important;
  }
}
:global(.goog-tooltip div) {
  background-color: white !important;
  font-family: inherit !important;
  box-shadow: vars.$shadow !important;
  border: 1px solid var(--color-gray-light) !important;
  border-radius: 2px;
  padding: 1rem !important;
}
