@use "../../theme/vars.scss";

.textField {
  input {
    border-width: 0px;
    border-bottom: 1px solid var(--color-gray-lighter);
    outline: none;
    background-color: transparent;
    padding: 3px 0;
  }
}
