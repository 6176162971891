@use "../../../theme/vars.scss";

.skos {
  height: calc(100vh - 50px - 64px);
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 1fr;

  .resizableBox {
    display: grid;
    grid-template-columns: 1fr auto;

    .skosTree {
      height: calc(100vh - 50px - 64px - 20px);
    }
  }

  .resizeHandle {
    &:hover > div {
      background-color: var(--color-gray-light);
      height: 100%;
      border-radius: 1px;
    }
    width: 10px;
    padding: 4px;
    cursor: col-resize;
  }
}

.hierarchyContainer {
  min-width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tree {
  overflow: auto;
}

.instancePaper {
  height: 100%;
  overflow-x: auto;
}

.instanceScroll {
  height: 100%;
  overflow: auto;
}

.filterButton {
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  margin-left: 10px;
}

.filters {
  margin-top: 1em;
}

.treeLoading {
  align-self: center;
  margin: 0 !important;
}

.treeItem {
  display: flex;
  gap: 10px;
  min-height: 30px;
  margin-block: 4px;
  align-items: center;
  padding-right: 5px;
  line-height: 1.2;
  justify-content: start;
  border-radius: 6px;

  .schemeLabel {
    margin-left: auto;
  }

  &:hover {
    background-color: #f3f4f6;
  }
}

.treeItemInTrail {
  background-color: #{"hsl(from var(--color-primary) h s l / 0.1)"};

  &:hover {
    background-color: #{"hsl(from var(--color-primary) h s l / 0.1)"};
  }
}

.treeItemActive {
  background: #{"hsl(from var(--color-primary) h s l / 0.1)"};
  color: var(--color-primary);

  .link {
    color: var(--color-primary);
  }
}
.treeItemChildren {
  display: flex;
  flex-direction: column;
  padding-inline-start: 20px;
}

.expandButton {
  height: 24px;
  line-height: 24px;
  flex: 0 0 24px;
  text-align: center;
  cursor: pointer;
  transform-origin: 14px 14px;
}

.expandButtonExpanded {
  transform: rotate(90deg);
}

.schemeLabel {
  max-width: 160px;
  text-overflow: "ellipsis";
  overflow: "hidden";
}

.link {
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.skosSchemeSelector {
  margin-bottom: 20px;
}

.innerTree {
  overflow: auto;
}

.skosSchemeSelector :global(.MuiInputBase-root) {
  padding-right: 10px !important;
  gap: 6px 1px;
}

.skosSchemeSelector :global(.MuiChip-root) {
  --skos-tree-triangle-width: 8px;
  margin-right: -5px;
}

.skosSchemeSelector :global(.MuiAutocomplete-input) {
  margin-left: 10px;
  min-width: 100px;
}

.skosSchemeSelector :global(.MuiChip-root .svg-inline--fa) {
  padding: 3px;
  box-sizing: border-box;
}

.skosSchemeSelector :global(.MuiChip-root:not(:first-child)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.skosSchemeSelector :global(.MuiChip-root):not(.end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.skosSchemeSelector :global(.MuiChip-root) {
  transition: none !important;
}

.skosSchemeSelector :global(.MuiChip-root).focus,
.skosSchemeSelector :global(.MuiChip-root:focus),
.skosSchemeSelector :global(.MuiChip-root:focus ~ .MuiChip-root) {
  outline: none;
  box-shadow: inset 0 0 0 3px var(--color-secondary);

  &:not(:first-child)::before {
    content: "";
    display: block;
    position: absolute;
    left: 3px;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 9px solid var(--color-secondary);
  }

  &:not(.end)::after {
    content: "";
    display: block;
    position: absolute;
    right: -11.5px;
    width: 20px;
    height: 20px;
    border-top: 15px solid var(--color-secondary);
    border-right: 15px solid var(--color-secondary);
    transform: scaleX(0.5) rotate(45deg);
  }
}

.skosSchemeSelector :global(.MuiChip-root).end {
  &::after {
    display: none !important;
  }
}

.skosSchemeSelector :global(.MuiChip-root:not(:first-child)):not(.end) {
  padding-left: 8px;
  padding-right: 10px;
  clip-path: polygon(
    calc(100% - var(--skos-tree-triangle-width)) 0px,
    100% 50%,
    calc(100% - var(--skos-tree-triangle-width)) 100%,
    0% 100%,
    var(--skos-tree-triangle-width) 50%,
    0% 0%
  );
}

.skosSchemeSelector :global(.MuiChip-root:first-child):not(.end) {
  padding-right: 6px;
  clip-path: polygon(
    calc(100% - var(--skos-tree-triangle-width)) 0px,
    100% 50%,
    calc(100% - var(--skos-tree-triangle-width)) 100%,
    0% 100%,
    0 0,
    0% 0%
  );
}

.skosSchemeSelector :global(.MuiChip-root).end:not(:first-child) {
  padding-right: 6px;
  padding-left: 8px;
  clip-path: polygon(
    100% 0px,
    100% 100%,
    calc(100% - var(--skos-tree-triangle-width)) 100%,
    0% 100%,
    var(--skos-tree-triangle-width) 50%,
    0% 0%
  );
}

div.schemeSelectorPopper :global(.MuiAutocomplete-noOptions) {
  display: none;
}

.skosSearchFieldOptionText {
  width: 100%;
}

.virtualItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.progressBar {
  height: 4px;
}

.innerTreeChild {
  width: 100%;
  position: relative;
}
