@use "../../theme/vars.scss";
@use "../../theme/zIndexes.scss";

.settingsMenu {
  flex-grow: 1;
  z-index: zIndexes.$second-navbar-z - 1;
  > * {
    font-size: 1.65rem;
  }
}
