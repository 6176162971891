@use "sass:map";
@use "vars.scss";

:global {
  // Used as fallback colors
  :root {
    --color-primary: #1690c6;
    --color-primary-contrast: #fff;
    --color-secondary: #fa8d3e;
    --color-secondary-contrast: #fff;
    --color-banner: #1690c6;
    --color-error: #f44336;
    --color-warning: #ff9800;
    --color-success: #5cb85c;
    --color-info: #1690c6;
    --color-link: #1690c6;
    --color-link-hover: #3fb5ea;
    --color-gray-lighter: #ebedef;
    --color-gray-light: #b3b3b3;
    --color-gray: #666666;
    --color-gray-dark: #333;
  }

  * {
    box-sizing: border-box;
  }

  html,
  body {
    font-size: vars.$font-size;
    color: #555555;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: vars.$line-height-base;
    background-color: var(--color-gray-lighter);
  }

  body {
    margin: 0;
  }

  th {
    text-align: start;
  }

  hr {
    border: 0;
    border-top: 1px solid var(--color-gray-lighter);
    box-sizing: content-box;
    height: 0;
  }

  .hidden-sm {
    @media (max-width: vars.$screen-sm-max) {
      display: none;
    }
  }

  .hidden-xs {
    @media (max-width: vars.$screen-xs-max) {
      display: none;
    }
  }

  .hidden {
    display: none;
  }

  label {
    font-weight: normal;
  }

  // input[type=search] styling is needed to show the search fields correctly in safari
  input[type="search"] {
    -webkit-appearance: textfield;
  }

  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  // don't show the search clear button in chrome and safari
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    display: none;
  }

  input,
  button,
  select,
  textarea {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  // For each header we have extra styling (margin-top and margin-right) to make it nicer.
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-family: inherit;
    font-weight: normal;
    color: var(--color-gray-dark);
    line-height: 1.3;
    &.headerSpacing {
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 1.2;
    }
  }

  // Each header component has their own custom font-size to keep the size the same as it was before removing bootstrap.
  h1 {
    font-size: 2.6em; // 39/15;
  }
  h2 {
    font-size: 2.133em; // 32/15
  }
  h3 {
    font-size: 1.733em; // 26/15
  }
  h4 {
    font-size: 1.266em; // 19/15
  }
  h5 {
    font-size: 1em; // 15/15
  }
  h6 {
    font-size: 0.866em; // 13/15
  }
  //override the user agent stylesheet
  figure {
    margin: unset;
  }

  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }
  .shadow {
    box-shadow: vars.$shadow;
  }

  .rounding {
    border-radius: 4px;
  }

  a {
    color: var(--color-link);
    text-decoration: none;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: inline-block;
      color: var(--color-link);
    }
    &:hover,
    &:focus {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-decoration: underline;
        outline: 0;
      }
    }
    &:focus-visible {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-decoration: underline;
        outline: vars.$focus-border;
      }
      * + h1,
      * + h2,
      * + h3,
      * + h4,
      * + h5,
      * + h6 {
        outline: 0;
      }
    }
  }
  a:hover,
  a:focus {
    color: var(--color-link-hover);
    text-decoration: underline;
    outline: 0;
  }
  a:focus-visible {
    outline: vars.$focus-border;
    border: none;
  }

  .white {
    background-color: white;
  }

  .whiteSink {
    background-color: white;
    padding: 1px;
    & > * {
      @extend .m-5;
    }
    @extend .my-6;
    @extend .rounding;
    @extend .shadow;
  }

  .addToListButton {
    @extend .ml-3;
    @extend .mt-3;
    @extend .shadow;
    @extend .rounding;
  }
  .removeFromListButton {
    @extend .mt-3;
    @extend .ml-3;
    @extend .mr-3;
    @extend .shadow;
    @extend .rounding;
  }
  .clickableIcon {
    @extend .p-2;
    cursor: pointer;
    &.disabled {
      color: var(--color-gray-light);
      cursor: not-allowed;
    }
    &:hover {
      color: var(--color-secondary);
      &.disabled {
        color: var(--color-gray-light);
      }
    }
  }

  .paragraphHeader {
    letter-spacing: 2px;
    color: var(--color-gray);
  }

  .flex {
    display: flex;
  }

  .column {
    flex-direction: column;
  }

  .center {
    align-items: center;
  }

  .horizontalCenter {
    justify-content: center;
  }

  .horizontalEnd {
    justify-content: flex-end;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .grow {
    flex-grow: 1;
  }

  .noShrink {
    flex-shrink: 0;
  }

  .nowrap {
    white-space: nowrap;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .break {
    word-break: break-word;
  }

  .constrainWidth {
    max-width: vars.$screen-lg-min;
    margin: auto;
  }

  .invisible {
    visibility: hidden;
  }

  .transparent {
    background: transparent;
  }

  .noLinkDecoration,
  .noLinkDecoration:visited,
  .noLinkDecoration:active,
  .noLinkDecoration:hover,
  .noLinkDecoration:link,
  .noLinkDecoration:focus {
    color: inherit;
    text-decoration: none;
  }

  .resetButton {
    min-width: 0;
    border-radius: 0;
    // font-size: 14px;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;
    border: 0;
    outline: none;
    background: transparent;
    white-space: nowrap;
    font-style: inherit;
    font-variant: inherit;
    font-family: inherit;
    padding: 0;
    cursor: pointer;
  }
  dl {
    display: grid;
    grid-template-columns: max-content auto;
    & > * {
      @extend .p-1;
    }
  }

  dt {
    grid-column-start: 1;
    font-weight: bold;
    @extend .mr-3;
  }

  dd {
    grid-column-start: 2;
  }

  //spacing
  //for example: use class py-2 for a padding-top and padding-bottom of size 2

  $spacer: 1rem !default;
  $spacer-x: $spacer !default;
  $spacer-y: $spacer !default;
  $spacers: (
    0: (
      x: 0,
      y: 0,
    ),
    1: (
      x: (
        $spacer-x * 0.17,
      ),
      y: (
        $spacer-y * 0.17,
      ),
    ),
    2: (
      x: (
        $spacer-x * 0.33,
      ),
      y: (
        $spacer-y * 0.33,
      ),
    ),
    3: (
      x: (
        $spacer-x * 0.66,
      ),
      y: (
        $spacer-y * 0.66,
      ),
    ),
    4: (
      x: $spacer-x,
      y: $spacer-y,
    ),
    5: (
      x: (
        $spacer-x * 1.33,
      ),
      y: (
        $spacer-y * 1.33,
      ),
    ),
    6: (
      x: (
        $spacer-x * 2,
      ),
      y: (
        $spacer-y * 2,
      ),
    ),
    7: (
      x: (
        $spacer-x * 2.66,
      ),
      y: (
        $spacer-y * 2.66,
      ),
    ),
    8: (
      x: (
        $spacer-x * 3.33,
      ),
      y: (
        $spacer-y * 3.33,
      ),
    ),
    9: (
      x: (
        $spacer-x * 4.33,
      ),
      y: (
        $spacer-y * 4.33,
      ),
    ),
  );

  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $lengths in $spacers {
      $length-x: map.get($lengths, x);
      $length-y: map.get($lengths, y);

      .#{$abbrev}-#{$size} {
        #{$prop}: $length-y $length-x;
      }
      .#{$abbrev}t-#{$size} {
        #{$prop}-top: $length-y;
      }
      .#{$abbrev}r-#{$size} {
        #{$prop}-right: $length-x;
      }
      .#{$abbrev}b-#{$size} {
        #{$prop}-bottom: $length-y;
      }
      .#{$abbrev}l-#{$size} {
        #{$prop}-left: $length-x;
      }
      .#{$abbrev}x-#{$size} {
        #{$prop}-right: $length-x;
        #{$prop}-left: $length-x;
      }
      .#{$abbrev}y-#{$size} {
        #{$prop}-top: $length-y;
        #{$prop}-bottom: $length-y;
      }
    }
  }

  @each $size, $lengths in $spacers {
    $length-x: map.get($lengths, x);
    $length-y: map.get($lengths, y);

    .g-#{$size} {
      gap: $length-y $length-x;
    }
    .gx-#{$size} {
      column-gap: $length-x;
    }
    .gy-#{$size} {
      row-gap: $length-y;
    }
  }
}

@media print {
  :global(.hideOnPrint) {
    display: none !important;
  }

  @page {
    margin: 1.9cm 1.9cm 2.54cm 1.9cm;
    /*size:18cm 23.5cm;*/
  }

  html,
  body {
    background-color: transparent;
  }
  image,
  figure,
  video,
  :global(.leaflet) {
    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid;
  }
  table tr th {
    width: auto !important;
  }
  table {
    width: auto !important;
  }
  table {
    border-collapse: collapse !important;
  }
}
