@use "../../theme/vars.scss";

.buttonStyle {
  border: none;
  cursor: pointer;
  background: none;
  &:not(:disabled) {
    &:hover:not(.disableHover) {
      color: var(--color-secondary);
    }
  }
  &:disabled {
    color: var(--color-gray);
    cursor: not-allowed;
  }
  &:focus-visible {
    outline: vars.$focus-border;
  }
  @media print {
    display: none;
  }
}
