@use "../../theme/vars.scss";
@use "../../theme/zIndexes.scss";

.link {
  display: inline-block;
  &:hover {
    .caption {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}
.imageWidget {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
  .imageWidgetImage {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
  .caption {
    position: absolute;
    bottom: 0;
    max-height: 100%;
  }
}
.mediaWidget {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;

  .caption {
    position: absolute;
    bottom: 0;
    max-height: 100%;
  }
}
.geoWidget {
  width: 120px;
  height: 120px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;

  // prevents a bottom margin

  .caption {
    position: absolute;
    bottom: 0;
    max-height: 100%;
    z-index: zIndexes.$geo-widget-caption-z;
  }
}
.caption {
  background-color: rgba(0, 0, 0, 0.41);
  color: white;
  width: 100%;
  max-height: 100%;
  padding: 5px;
  word-break: break-word;
}
.imageContainer {
  position: absolute;
}
.image {
  width: 120px;
  height: 120px;
  object-fit: cover;
}
