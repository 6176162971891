.queryWidget {
  background-color: white;
  display: flex;
  flex-direction: column;
  .header {
    word-break: break-word;
    .queryTypeIcon {
      font-size: calc(80px / 3);
    }
    .headerContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .titleRow {
      align-items: center;
      width: 100%;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 1.733em; // Style h2 as h3
      color: inherit;
    }
  }
  .footer {
    margin-top: auto;
  }
}
