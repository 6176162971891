@use "../../theme/vars.scss";

.header {
  align-items: center;
}

.primaryInfo {
  margin: 15px 0px 15px 20px;
}

.title {
  align-items: center;

  > h1 {
    margin: 0;
  }

  > i {
    margin-left: 15px;
  }
}

.by {
  align-items: center;

  & > h4 {
    margin: 0;
    padding: 7px 0px 10px 3px;
  }
}

.created,
.statements {
  font-size: 95%;
}

.graph {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(50% + 300px);
}

.nr {
  background-color: var(--color-gray-lighter);
  font-size: 90%;
  & > div {
    padding: 2px 5px;
  }
}

.exampleResources {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-bottom: -10px;
  > * {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.alert {
  margin-top: 20px;
}

.graphsHeading {
  display: flex;
  align-items: center;
}

.dependentsContainer {
  gap: 20px;
}

.dependentsList {
  gap: 6px;
  flex-direction: column;
  word-break: break-word;
}

.flippedIcon {
  transform: rotate(90deg) scaleX(-1);
}

.menu {
  align-self: flex-start;
}
.menuItemNoLink:focus {
  color: inherit;
  text-decoration: none;
}
.menuItemNoLink:hover {
  color: inherit;
  text-decoration: none;
}
