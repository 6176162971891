@use "../../theme/vars.scss";

.box {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  h4 {
    margin: 0;
    //use bottom border instead of text decoration, to increase spacing
    //between font and line
    display: inline-block; //otherwise border will span complete width
    &:hover {
      cursor: pointer;
    }
  }
}

.avatar {
  margin: 4px 0px;
  a {
    color: var(--color-gray);
  }
}
.roleSelectField {
  width: 130px;
  margin-right: 10px;
}
.colAvatar {
  padding-right: 20px;
}
.colDescription {
  flex-grow: 1;
}
.description {
  word-break: break-all;
  word-wrap: break-word;
  margin-top: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 0; //override browser default. padding is done by parent already, and having browser-defaulted padding
  //will messup vertical central alignment
}
.colIcons {
  i {
    font-size: 130%;
  }
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

div.admin {
  font-size: 90%;
}
.remove {
  margin-left: 15px;
  cursor: pointer;
}
