@use "../../theme/vars.scss";

.userWidget {
  color: white;
  background-color: transparent;
  display: flex;
  align-items: center;
  > a {
    outline: none;
    border: none;

    &:hover,
    &:focus {
      text-decoration: underline;
      cursor: pointer;
    }
    &:focus-visible {
      outline: vars.$focus-border;
    }
  }
}

.dropDownMenu {
  margin-top: 12px;
  margin-left: 12px;

  .customDivider {
    outline: 1px solid var(--color-gray-lighter);
    outline-offset: 8px;
    margin-top: 16px;
    // IE 11
    @media screen and (-ms-high-contrast: none) {
      outline: 0;
    }
  }
  ul {
    list-style-type: none;
    overflow: hidden;
  }
  li {
    list-style-type: none;
    min-height: inherit;
    @media all and (-ms-high-contrast: none) {
      list-style-image: none;
    }
    @supports (-ms-ime-align: auto) {
      list-style-image: none;
    }

    a:focus {
      //avoid showing of outline briefly on click (focus)
      outline: none;
    }
  }
  .selected:global(.Mui-selected) {
    background-color: var(--color-secondary);
    color: var(--color-secondary-contrast);
    &:hover {
      background-color: var(--color-secondary);
      color: var(--color-secondary-contrast);
    }
  }
}
