@use "../../../theme/vars.scss";

.resource {
  display: flex;
  background-color: white;
  width: 50%;
  float: left;
  overflow: hidden;
}

.contentBox {
  flex-grow: 1;
  h2 {
    color: inherit;
  }
  position: relative;
  overflow: hidden;
}

.contentDragArea {
  position: relative;
  width: 350%;
  left: -100%;

  @media (min-width: vars.$screen-lg-min) {
    width: calc(700% / 3);
    left: calc(-200% / 3);
  }
  &.noTransition .content {
    transition: none;
  }
}

.content {
  display: flex;
  overflow: auto;
  position: relative;
  transition: transform 600ms;
  // width: calc(200% / 3);
  width: calc(400% / 7);

  @media (min-width: vars.$screen-lg-min) {
    width: calc(500% / 7);
  }

  &:global(.react-draggable-dragging) {
    transition: none;
  }
}

.resourceContainer {
  background-color: var(--color-primary);
  color: var(--color-primary-contrast);
  width: calc(100% / 3 + 15px);
  display: none;
  overflow: hidden;
  button:focus-visible {
    border: vars.$focus-border;
    outline: none;
  }

  @media (min-width: vars.$screen-lg-min) {
    display: flex;
  }
}

.resourceId {
  word-wrap: break-word;
  flex-grow: 1;
  overflow: auto;
}
.description {
  white-space: pre-wrap;

  a {
    color: var(--color-primary-contrast);
    text-decoration: underline;
    &:hover {
      font-weight: bold;
    }
  }
  &.htmlDescription {
    white-space: normal;
  }
}

.resourceIdSmall {
  word-wrap: break-word;
  background-color: var(--color-primary);
  color: var(--color-primary-contrast);
  width: 100%;
  overflow: auto;
  display: block;

  @media (min-width: vars.$screen-lg-min) {
    display: none;
  }
}

.navIcon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--color-primary);
  filter: brightness(0.9);
  color: var(--color-primary-contrast);
  min-width: 30px;

  &:hover {
    filter: brightness(0.8);
  }
  svg {
    margin-top: 47px;
    margin-bottom: 12px;
  }
}

.navIconSm {
  @extend .navIcon;
  background-color: var(--color-primary);
  @media (min-width: vars.$screen-lg-min) {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid var(--color-gray-lighter);
}

.inLinkProperties,
.outLinkProperties {
  overflow: auto;
  width: 100%;

  @media (min-width: vars.$screen-lg-min) {
    width: calc(200% / 3);
  }
}

.inLinkProperties > div + div {
  border-top: 1px solid var(--color-gray-lighter);
  margin-top: 25px;
  padding-top: 20px;
}

.resourceImage {
  max-width: 100%;
  max-height: 200px;
  border-radius: 2px;
  margin-top: 20px;

  @media (min-width: vars.$screen-lg-min) {
    max-height: 100%;
  }
}

.externalLink {
  font-size: 13px;
  margin-top: 16px;

  & > a {
    color: var(--color-primary-contrast);
  }
}

.types {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  justify-content: left;
  margin-left: -10px;
}

.type {
  background-color: var(--color-primary-contrast);
  border-radius: 10px;
  padding: 8px 12px;
  margin-top: 5px;
  color: var(--color-primary);
  margin-left: 10px;
  &:focus-within {
    // with focus on the anchor tag, give border to the div
    outline: vars.$focus-border;
  }
  a {
    outline: none;
  }
}

.outLink + .outLink {
  margin-top: 25px;
}
.media {
  width: 100%;
  max-height: 400px;
  &:focus {
    // Webkit highlights the video when clicked
    outline: none;
  }
}
