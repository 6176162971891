@use "../../theme/vars.scss";

.box {
  display: flex;
  align-items: center;
  padding: 10px 15px;

  h4.accountTitle {
    display: inline-block; //otherwise border will span complete width
  }
}
.email {
  display: inline-block;
  margin: 10px;
  color: var(--color-gray);
}
.avatar {
  margin: 4px 0px;
  a {
    color: var(--color-gray);
  }
}
.colAvatar {
  padding-right: 20px;
}
.colDescription {
  flex-grow: 1;
}
.descriptionCol {
  word-break: break-all;
  word-wrap: break-word;
  margin-top: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 0; //override browser default. padding is done by parent already, and having browser-defaulted padding
  //will messup vertical central alignment
}

//Make rule more specific, so we're overwriting the default markdown styling
div.description {
  margin-top: 5px;
  max-height: 180px;
  overflow: hidden;
}
.accountDetails {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
div.accountLabel {
  font-size: 90%;
  margin: 2px;
}
.space {
  flex-grow: 1;
}
