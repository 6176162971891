@use "../../theme/vars.scss";

.container {
  overflow: hidden;
}

.titleContainer {
  background-color: var(--color-primary);
  color: var(--color-primary-contrast);
  padding-top: 7px;
  padding-bottom: 7px;
}

.title {
  flex-grow: 1;
  padding: 4px;
  font-size: 110%;
}

.plusIcon:hover {
  transform: scale(1.15);
}
