@use "../../../theme/vars.scss";

.serviceTypes {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .serviceType {
    width: 170px;
    height: 100px;
    border-radius: 4px;
    cursor: pointer;
    background: transparent;
    border: 1px solid var(--color-gray-lighter);
    .details {
      font-size: 12px;
    }
    &.active {
      border-color: var(--color-primary);
      color: var(--color-primary);
      &:hover {
        color: var(--color-primary);
        background-color: transparent;
      }
    }
    &:hover {
      background-color: var(--color-gray-lighter);
    }
    &:focus {
      outline: 1px solid var(--color-primary);
    }
  }
}

.btns {
  & > * {
    margin-right: 5px;
  }
}

.inactiveAvatar {
  opacity: 0.2;
}
