@use "../../theme/vars.scss";

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--color-gray);
}

.spacing {
  :global(.MuiDialog-paper) {
    padding: 15px;
    gap: 15px;
  }
}
