@use "index.scss";

.storyFooter {
  @extend .constrainWidth;
  display: flex;
  flex-wrap: wrap;
}
.sourceDatasets {
  flex-grow: 1;
}
