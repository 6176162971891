@use "../../theme/vars.scss";
@use "../../theme/zIndexes.scss";

$bgColor: #f5f5f5;

.container {
  display: flex;
  justify-content: center;
  & > div {
    @media (min-width: vars.$screen-md-min) {
      width: 700px;
    }
  }
  &.ssoDisabled > div {
    @media (min-width: vars.$screen-md-min) {
      width: 432px;
    }
  }
  &:not(.ssoDisabled).passwordSignUpDisabled > div {
    @media (min-width: vars.$screen-md-min) {
      width: 432px;
    }
  }
}

.small {
  @media (min-width: vars.$screen-md-min) {
    display: none;
  }
}
.large {
  display: none;
  @media (min-width: vars.$screen-md-min) {
    display: block;
  }
}

.title {
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
}
.content {
  display: flex;
  flex-direction: column;
  @media (min-width: vars.$screen-md-min) {
    flex-direction: row;
  }
  @media (max-width: vars.$screen-md-min) {
    //Show password signup on top for small screens
    flex-direction: column-reverse;
  }
}

.box {
  h3 {
    text-align: center;
    text-decoration: none;
    position: relative;
    padding-bottom: 10px;
    color: var(--color-gray);
    flex-grow: zIndexes.$authentication-separator-line-z;
  }

  h3:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 1px;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: var(--color-gray);
  }
}
.openIdProviders {
  flex-basis: 50%;
  text-align: center;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    list-style: none;
    padding: 0;
    li {
      margin: 10px 0;
      min-width: 200px;
      img {
        vertical-align: middle;
        height: 20px;
        width: 20px;
      }
    }
    li > * {
      width: 100%;
    }
  }
}
.authForm {
  flex-basis: 50%;
}

.separator {
  margin-left: 30px;
  //Styling for small screens

  .line {
    height: 100%;
    width: 2px;
    background: #666;
    z-index: zIndexes.$authentication-separator-box-z;
  }
  .wordwrapper {
    background-color: white; //needs to be same color as our sink, defined in theme/global.scss
    color: #666;
    text-transform: uppercase;
    z-index: zIndexes.$authentication-separator-box-z;
    position: relative;
    font:
      700 12px arial,
      sans-serif;
    left: -46%;
    top: -55%;
    padding: 6px;
    letter-spacing: 1px;
    transform: translate(0, 0) rotate(90deg);
  }
}
//Show separator horizontally for smaller screens
@media (max-width: vars.$screen-md-min) {
  .separator {
    margin-top: 30px;
    margin-left: 0px;
    transform: translate(0, 0) rotate(0deg);

    .line {
      width: 100%;
      height: 1px;
      background: #666;
    }
    .wordwrapper {
      transform: translate(0, 0) rotate(0deg);
      position: absolute;
      left: 46%;
      top: -12px;
    }
  }
}

.paper > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 2rem;
  max-width: 500px;

  .label {
    margin-bottom: 0;
  }
  .labelSubtle {
    margin-bottom: 0;
    margin-top: 0;
    font-size: small;
    font-style: italic;
  }

  .errorText {
    font-size: 0.9rem;
    color: var(--color-error);
  }

  .recoveryCodeContainer {
    flex-wrap: wrap;
  }
  .recoverText {
    font-size: small;
    font-style: italic;
    margin-bottom: 0;
  }
}
