.header {
  display: flex;
  .prefixIri {
    margin-left: 35px;
  }
}
.prefixRow {
  display: flex;
}
.prefixLabel {
  margin: 0 10px;
  width: 80px;
}
.prefixIri {
  flex-grow: 1;
  margin: 0 10px;
  .inputField {
    margin-left: 8px;
    flex-grow: 1;
  }
}
.addBtn {
  margin-bottom: 10px;
}
