.formField {
  border-radius: 1px;

  &:hover,
  &:focus-within {
    background-color: #{"hsl(from var(--color-primary) h s l / 0.1)"};
    outline: 10px solid #{"hsl(from var(--color-primary) h s l / 0.1)"};
  }

  &.error {
    > div > label {
      color: var(--color-error);
    }

    background-color: #{"hsl(from var(--color-error) h s l / 0.1)"};
    outline: 10px solid #{"hsl(from var(--color-error) h s l / 0.1)"};
  }
}

.appendField {
  align-content: flex-end;
}
