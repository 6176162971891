@use "../../theme/vars.scss";

.extLink {
  font-size: 12px;
  color: var(--color-gray);
  visibility: hidden;
  word-break: break-all;
  overflow: hidden;
  white-space: normal;
}

.link {
  max-height: 200px;
  // overflow: hidden;
  //we only have 1 icon. if we have more (dynamic) icons
  //we might need to disable this
  white-space: nowrap;
}

.prefixLabel {
  font-size: 13px;
  align-self: center;
}
