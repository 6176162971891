@use "../../theme/vars.scss";
.tokenItem {
  display: flex;
  align-items: center;

  .tokenAuthIcon {
    font-size: 1em;
  }
  .tokenContent {
    padding: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    > div {
      display: flex;
    }
    .tokenItemName {
      font-weight: bold;
      word-break: break-word;
      font-size: 1.2rem;
      margin-top: 0;
    }
    .tokenAuthLabel {
      font-weight: normal;
      font-size: 0.9rem;
    }
    .tokenItemIssuedAt {
      padding: 10px 10px 10px 0px;
      flex-grow: 1;
      font-size: small;
      align-self: center;
      flex-basis: 100px;
    }
    .tokenItemIssuedTitle {
      font-weight: bold;
    }
  }
  .tokenItemBtns {
    display: flex;
    font-size: 130%;
    > * {
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .editToken {
      cursor: pointer;
      color: var(--color-gray);
      &:hover {
        color: var(--color-warning);
      }
    }
  }
}
