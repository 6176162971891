@use "../../../theme/vars.scss";

.segment {
  display: flex;
  align-items: center;
  padding: 1px 0px;
  border: 0px solid var(--color-primary); // Width is assigned in the media queries
  &:hover {
    background-color: var(--color-primary);
    color: var(--color-primary-contrast);
  }
  &:hover ~ a {
    background-color: transparent;
    color: var(--color-primary);
  }
}

// Fold right to left on larger screens
@media (min-width: vars.$screen-xs-min) {
  .segment {
    border-top-width: 1px;
    border-bottom-width: 1px;
    &:first-child {
      border-left-width: 1px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    &:last-child {
      border-right-width: 1px;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .caret {
    margin-left: -1px;
  }
}

// Fold bottom to top on small screen
@media (max-width: vars.$screen-xxs-max) {
  .segment {
    display: flex;
    flex-direction: column;
    border-left-width: 1px;
    border-right-width: 1px;
    &:first-child {
      border-top-width: 1px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }

    &:last-child {
      border-bottom-width: 1px;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    .caret {
      // Change the direction of the arrow
      transform: rotate(90deg);
      margin-top: -6px;
      margin-bottom: -6px;
    }
  }
}
