.autoCompleteEditor {
  .loading {
    display: none;
  }
  :global(.Mui-focused) {
    .loading {
      display: block;
    }
  }
}
.readOnly {
  background-color: var(--color-gray-lighter);
  color: var(--color-gray-light);
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: not-allowed;
}
