@use "../../theme/zIndexes.scss";
@use "../../theme/vars.scss";

$transition-duration: 0.5s;

.container {
  position: relative;
}

.focusDrawer {
  margin-top: vars.$navbar-height;
  max-height: calc(100vh - #{vars.$navbar-height});
  max-width: 80%;
  width: 500px;
  .focusDrawerHeader {
    padding: 15px 16px 11px 24px;
    display: flex;
    justify-content: space-between;
    background-color: var(--color-primary);
    * {
      color: var(--color-primary-contrast);
    }
  }

  .focusDrawerContent {
    padding: 20px;

    .propertyList {
      display: grid;
      grid-template-columns: auto auto min-content;
      padding: 10px 6px;
      &:empty {
        display: none;
      }

      .owlDot,
      .shaclDot {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        flex-shrink: 0;
      }

      .owlDot {
        background-color: var(--color-primary);
      }
      .shaclDot {
        background-color: var(--color-error);
      }

      > div {
        padding: 3px 8px;
      }
      .bg {
        background-color: var(--color-gray-lighter);
      }
      .left {
        border-top-left-radius: 4px;
        &:not(.open) {
          border-bottom-left-radius: 4px;
        }
      }
      .right {
        border-top-right-radius: 4px;
        &:not(.open) {
          border-bottom-right-radius: 4px;
        }
      }

      .clickable {
        cursor: pointer;
      }

      .details {
        grid-column-start: 1;
        grid-column-end: 4;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 7px 10px 10px 20px;
        max-width: 450px;
      }
    }
  }
}
.draggable:not(.class) {
  cursor: grab;
}
.toolbarContainer {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;

  .toolbar {
    &.hidden {
      display: none;
    }
    padding: 4px;
    display: flex;
    gap: 1rem;
    align-items: center;
    max-width: calc(100% - 40px);
    overflow-x: auto;

    > * {
      box-shadow: vars.$shadow;
    }

    .chip {
      cursor: pointer;
      max-height: 28px;
      .icon {
        font-size: 14px;
      }
    }

    .toggleButton {
      font-size: smaller;
      line-height: 13px;
    }

    .owl.color {
      color: var(--color-primary);
    }

    .shacl.color {
      color: var(--color-error);
    }

    .hierarchy.color {
      color: var(--color-success);
    }

    .properties.color {
      color: var(--color-secondary);
    }
  }
}
.fabSpace {
  position: fixed;
  top: 5rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
}
.badge {
  z-index: 1050;
  margin: 3px;
}
.fullSize {
  width: 100%;
  height: 100%;
}
.visualizationContainer {
  height: calc(100vh - #{vars.$navbar-height});
  width: 100%;
}

.graph {
  --bgColor: #f5f5f5;
  --primaryColor: red;
  --hierarchyEdgeColor: #7f7f7f;
  --propertyEdgeColor: var(--color-gray-dark);

  &.highlightHierarchy .edge:not(.hierarchy),
  &.highlightHierarchy .edgeLabel {
    opacity: 0.2;
  }
  &.highlightProperties .edge:not(.property) {
    opacity: 0.2;
  }
  &.highlightOwl {
    .edge:not(.owl),
    .edgeLabel:not(.owl),
    .datatypeProperty:not(.owl) {
      opacity: 0.2;
    }
    .node:not(.owl) {
      .nodeDiv {
        opacity: 0.2;
      }
    }
  }
  &.highlightShacl {
    .edge:not(.shacl),
    .edgeLabel:not(.shacl),
    .datatypeProperty:not(.shacl) {
      opacity: 0.2;
    }
    .node:not(.shacl) {
      .nodeDiv {
        opacity: 0.2;
      }
    }
  }

  width: 100%;
  height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  user-select: none;

  > svg {
    width: 100%;
    height: 100%;
    background-color: var(--bgColor);

    .arrowHead {
      fill: var(--propertyEdgeColor);
    }
    .subClassArrow {
      fill: var(--bgColor);
      stroke: var(--hierarchyEdgeColor);
    }
  }
}
.settingsDrawer {
  z-index: (zIndexes.$second-navbar-z - 1);
  width: 400px;
  margin-top: vars.$navbar-height;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 80%;
}

.node {
  transition: transform $transition-duration;
  > foreignObject {
    overflow: visible;
  }
}

.nodeDiv {
  border: 1px solid var(--primaryColor);
  border-color: inherit;
  background-color: white;
  border-radius: 4px;
  display: inline-block;
  max-width: 500px;
  cursor: pointer;
  &:hover {
    background-color: #fff8f7;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-left: 10px;
    padding-right: 3px;

    h5 {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }
  }

  .title,
  .datatypeProperties {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .block {
    border-radius: 4px;
    min-width: 15px;
    height: 15px;
  }

  .datatypeProperties {
    padding: 10px;
    border-top: 1px solid var(--primaryColor);
    border-color: inherit;

    .datatypeProperty {
      display: flex;
      column-gap: 5px;
      flex-wrap: wrap;
      align-items: center;

      em {
        padding-right: 2px;
        margin-left: auto;
      }
    }
  }
}

.blur {
  opacity: 0.1;
}

.edge {
  &.hierarchy {
    stroke: var(--hierarchyEdgeColor);
  }
  fill: none;
  > path {
    transition: d $transition-duration;
  }
}

.edgeLabel {
  transition: transform $transition-duration;
  cursor: pointer;
  rect {
    fill: white;
  }
}

.searchButton,
.filterButton {
  padding: 0 12px;
  &:focus-visible {
    outline: 3px solid var(--color-secondary) !important;
  }
  &.focusVisible {
    outline: none;
  }
  &.collapsed {
    max-width: 40px;
  }
  &:not(.collapsed) {
    background-color: white;
  }
}
.searchField,
.filterField {
  margin-right: 10px;
  margin-left: 5px;
  max-width: calc(100vw - 80px);
}
.searchField {
  width: 300px;
}
.filterButton {
  align-items: flex-start;
  height: auto;
  min-height: 40px;
  > svg {
    margin-top: 12px;
  }
}
.filterField {
  .chip {
    text-transform: none;
  }
  .inputRoot {
    min-height: 39px;
  }
  padding: 10px 0;
  width: 400px;
}
