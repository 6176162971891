@use "../../theme/vars.scss";

.lowerCircle {
  stroke: var(--color-gray-lighter);
  stroke-width: 1rem;
  fill: transparent;
}
.upperCircle {
  stroke: var(--color-primary);
  stroke-width: 1rem;
  fill: transparent;
  transition:
    stroke-dashoffset 1.5s linear,
    stroke 1s linear;
  &.warning {
    stroke: var(--color-error);
  }
}
.circle {
  overflow: hidden;
}
.limitWidget {
  position: relative;
}
.limitBox {
  position: absolute;
  display: flex;
  bottom: 1rem;
  left: 0;
  right: 0;
  justify-content: center;
}
