.cacheHeader {
  display: flex;
}

.cacheHeaderToggle {
  align-self: center;
  width: auto;
}

.space {
  flex-grow: 1;
  flex-basis: 1rem;
}
