@use "../../theme/zIndexes.scss";

.popper {
  z-index: zIndexes.$popper-z;
}

.paper {
  max-height: calc(50vh - 50px);
  max-width: calc(100vw - 10px);
  overflow-y: auto;

  :global(.MuiMenuItem-root) {
    white-space: normal;
    word-break: break-all;
  }
}

.chips > * {
  margin: 5px;
}

.chips,
.items {
  &:not(:empty) {
    margin-bottom: 20px;
  }

  & + div {
    margin-top: 10px;
  }
}

.sortableElement {
  display: flex;
  align-items: center;
  padding: 5px 0px;

  > i {
    padding: 5px;
  }

  .handle {
    cursor: ns-resize;
  }

  .itemLabel {
    flex-grow: 1;
    word-break: break-all;
    word-wrap: break-word;

    // line-height: 24px;
    // color: rgba(0, 0, 0, 0.87);
  }
}

.cursorOverride {
  pointer-events: auto !important;
}

.autoSuggestRoot {
  flex-grow: 1;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.suggestion {
  display: block;
}
