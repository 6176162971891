@use "../../theme/vars.scss";

.quickNavigateSearch {
  display: flex;
  flex-direction: row;
  border-radius: 1.625rem;
  &:focus-within,
  &:focus-visible,
  &:hover {
    //Targeting only input ctr
    > div {
      border: 2px solid var(--color-secondary);
    }
  }
}

.searchInput {
  border: 0;
  outline: 0;
  width: 100%;
  flex-shrink: 1;
  opacity: 1;
  flex: 1;
  margin-top: 1px;
  margin-left: 1px;
  padding-left: 15px;
  @media all and (-ms-high-contrast: none) {
    font-size: 1.2em;
  }
}

.searchInputContainer {
  background: white;
  border-radius: 1.625rem 0 0 1.625rem;
  border: 2px solid var(--color-gray-lighter);
  padding: 0.2rem 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:focus-within {
    > .hotkeys {
      display: none;
    }
  }
}

.searchButton {
  border-radius: 0px 1.625rem 1.625rem 0;
  background-color: var(--color-secondary);
  color: white;
  &:hover {
    background-color: var(--color-secondary);
  }
  padding: 5px 20px;
}

@media (max-width: vars.$screen-xxs-max) {
  .quickNavigateSearch.mayShrink {
    width: unset;
    min-width: unset;
    background: none;
    border: 0;

    .placeholder {
      display: none;
    }
  }
}

@media (max-width: vars.$screen-sm-max) {
  .hotkeys {
    display: none;
  }
}
