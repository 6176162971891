@use "../../theme/vars.scss";

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: white;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25em;
  white-space: nowrap;
}

.error {
  background-color: var(--color-error);
}
.warning {
  background-color: var(--color-warning);
}

.primary {
  background-color: var(--color-primary);
  color: var(--color-primary-contrast);
}
.secondary {
  background-color: var(--color-secondary);
  color: var(--color-secondary-contrast);
}

.success {
  background-color: var(--color-success);
}
.neutral {
  background-color: var(--color-gray-light);
}
