@use "../../theme/vars.scss";

.storyBadge {
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  &.medium {
    height: 50px;
    min-width: 50px;
    max-width: 50px;
    font-size: 22px;
  }
  border-radius: 3px;
  background-color: var(--color-gray-light);
  color: white;
  justify-content: center;
}
