.alert {
  margin: 15px;
}

.deleteBtn {
  margin-top: 10px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  margin: -20px;

  & > * {
    flex-grow: 1;
    margin: 20px;
  }
}
