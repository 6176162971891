@use "../../theme/vars.scss";

.logoBg {
  display: flex;
  align-content: center;
  justify-content: center;
}

.circleBg {
  border-radius: 50%;
}

.squareBg {
  border-radius: 20%;
}

.noBg {
  border-radius: unset;
}

.showBorder {
  border: 1px solid #d3d3d3;
}
