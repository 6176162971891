@use "../../../theme/vars.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 150ms ease-in-out;
  .formInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .textInput {
      flex-grow: 1;
      margin: 0 0.5rem;
      font-size: 1.65rem;
      &:focus {
        border-bottom: 3px solid var(--color-primary);
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      & > * {
        margin: 0 0.15rem;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-25%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
