@use "../../theme/vars";

$animateDuration: 0.2s;
$panelEase: cubic-bezier(0.4, 0, 0.2, 1);
$iconSize: 20px;

$panelWidthOpen: 240px;
$panelWidthCollapsed: 60px;
$separatorColor: rgba(0, 0, 0, 0.12);

$panelLeftPadding: 18px;
$iconsSize: 20px;
$iconRightSpacing: 14px;

$showSmallPanelOnSize: "(max-width : #{vars.$screen-xs-max})";
$showLargePanelOnSize: "(min-width : #{vars.$screen-sm-min})";
