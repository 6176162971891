@use "../../theme/vars.scss";

.fullPage {
  height: calc(100vh - 50px);
  overflow: hidden;
}

.container {
  width: 100%;
  min-height: 520px;
  overflow-x: hidden;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.noContentMsg {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.instanceSearch {
  width: 350px;
  flex-shrink: 1;

  :global(.Mui-focused) {
    .loading {
      display: block;
    }
  }

  .loading {
    display: none;
  }
}
.createButton {
  flex-shrink: 0;
}

.groupHeader {
  letter-spacing: 2px;
  background: var(--color-primary);
  padding: 7px 10px;
  color: var(--color-primary-contrast);
  border-radius: 4px;
}

.groupHeaderGray {
  background: var(--color-gray);
  color: white;
}
