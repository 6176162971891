@use "../../theme/vars.scss";
@use "../../theme/zIndexes.scss";

div.barOuter {
  background: var(--color-secondary);
  height: 2px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: zIndexes.$global-loading-bar-z;
}

div.barInner {
  boxshadow:
    0 0 10px var(--color-secondary),
    0 0 5px var(--color-secondary);
  display: block;
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  transform: rotate(3deg) translate(0px, -4px);
  width: 100px;
}
