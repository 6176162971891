@use "../../theme/vars.scss";

.icon {
  img {
    height: 300px;
    color: var(--color-primary);
    // IE 11 hack
    @media screen and (-ms-high-contrast: none) {
      width: 157px;
    }
  }
}
.iconRow {
  max-height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.container {
  height: 100;
  animation-name: showDelayed;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes showDelayed {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
