@use "../../../theme/vars.scss";
@use "../../../theme/zIndexes.scss";

.form {
  flex-flow: column;
  & .varNameAutocomplete {
    width: 375px;
    max-width: 100%;
  }
}
.warning {
  color: var(--color-warning);
  cursor: default;
}
.autocompleteItem {
  white-space: pre;
  .autocompleteHighlight {
    font-weight: bolder;
  }
}

.variableType {
  width: 375px;
  max-width: 100%;
}

.language,
.dataType {
  width: 375px;
  margin-left: 20px;
  max-width: 100%;
  :global(.MuiInputAdornment-root) {
    margin-right: 0;
  }
}
.defaultValue {
  width: 100%;
}
.allowedValuesWrapper {
  .action:hover {
    color: var(--color-secondary);
  }
}

.popper {
  z-index: zIndexes.$popper-z;
}
.paper {
  max-height: calc(50vh - 50px);
  max-width: calc(100vw - 10px);
  overflow-y: auto;

  :global(.MuiMenuItem-root) {
    white-space: normal;
    word-break: break-all;
  }
}

.cursorOverride {
  pointer-events: auto !important;
}
.listContainer {
  background: #eee;
}
.listItem {
  &:hover {
    background-color: var(--color-gray-lighter);
  }
  &.dragging {
    background-color: var(--color-gray-lighter);
    cursor: ns-resize;
  }

  .enabledDragHandle {
    cursor: ns-resize;
  }
}
