.tableContainer {
  max-height: 100%;
  overflow-y: auto;
  position: relative;
  .table {
    min-width: 100%;
    // margin-bottom: 52px;
  }
  .footer {
    display: block;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    :global(.MuiToolbar-root) {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    @media print {
      display: none;
    }
    &.hidePagination {
      display: none;
    }
  }
}

.prefixTooltip {
  background-color: white;
  color: inherit;
  border: 1px #eee solid;
  padding: 0 1rem;
  max-width: 500px;
  word-break: break-word;
}
.cell {
  border: none;
  // Small fix for showing empty rows to at least have a simple height
  &:empty {
    height: 2rem;
  }
}

.cellContent {
  max-height: 100px;
  min-width: 75px;
  overflow-y: auto;
  overflow-x: hidden;
  width: fit-content;
  word-break: break-word;
}
.header {
  position: relative;
  padding-right: 0.75rem;
  .headerButton {
    width: 100%;
  }
  @media print {
    .headerButton {
      display: contents;
    }
  }
  .dragHandle {
    visibility: hidden;
    position: absolute;
    border-radius: 0.5rem;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: col-resize;
    border-right-width: thick;
    &:hover {
      &:global(.MuiDivider-root) {
        border-right-width: thick;
        border-color: var(--color-primary);
      }
    }
  }
  &:hover .dragHandle {
    visibility: visible;
  }
}
.tableDrag {
  cursor: ew-resize;
  user-select: none;
  .header {
    pointer-events: none;
  }
  .isDragging {
    visibility: visible;
    &:global(.MuiDivider-root) {
      border-right-width: thick;
      border-color: var(--color-primary);
    }
  }
}

.headerCell {
  padding-right: 6px;
  &:last-child {
    padding-right: 10px;
  }
}

.hidePaginationPerRows {
  :global(p.MuiTablePagination-selectLabel) {
    display: none;
  }

  :global(div.MuiTablePagination-select) {
    display: none;
  }

  :global(div.MuiTablePagination-input) {
    display: none;
  }
}
