@use "../../../theme/vars.scss";

.node {
  align-items: center;
}

.label {
  background: inherit;
  border: none;
  text-align: left;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  &:focus-visible {
    outline: vars.$focus-border;
  }
}

.expander {
  width: 1em;
  text-align: center;
  background: inherit;
  border: none;

  &:hover {
    color: var(--color-secondary);
    cursor: pointer;
    background-color: #f5f5f5;
  }
  &:focus-visible {
    outline: vars.$focus-border;
  }
}
