@use "index.scss";

.paragraph {
  @extend .constrainWidth;
}

.markdown {
  word-break: break-word;
  overflow-x: auto;
  overflow-y: hidden;
}
