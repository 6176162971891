@use "../../theme/vars.scss";

.container {
  display: flex;
  justify-content: center;

  & > div {
    @media (min-width: vars.$screen-md-min) {
      width: 50%;
    }
  }
}
