@use "../../../theme/vars.scss";

.topic {
  color: var(--color-primary);
  display: flex;
  &:hover > a {
    background-color: var(--color-primary);
    color: var(--color-primary-contrast);
  }
  > a:focus-visible {
    border: vars.$focus-border;
    outline: none;
  }
}
// Fold bottom to top on small screen
@media (max-width: vars.$screen-xxs-max) {
  .topic {
    // Fold the items downward
    flex-direction: column;
  }
}
