@use "../../theme/vars.scss";

.extLink,
.language {
  font-size: 12px;
  color: var(--color-gray);
  visibility: hidden;
  word-break: break-all;
  overflow: hidden;
  white-space: normal;
}
.wrapper {
  white-space: pre-wrap;
}
