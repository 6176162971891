@use "index.scss";

h1.lncsTitle {
  font-size: 14pt !important;
  font-family: "CMU Serif Bold", serif !important;
  text-align: center !important;
  margin-top: 1.5em;
  margin-bottom: 0.9em;
}

.header {
  position: relative;
  &:hover {
    .accessLevelIcon {
      visibility: visible;
    }
  }
}

.bannerImage {
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: #999;
  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    filter: brightness(60%);
  }
}

.bannerContent {
  @extend .constrainWidth;
  padding-top: 50px;
  padding-bottom: 50px;
  word-break: break-word;

  .accessLevelIcon {
    visibility: hidden;
  }

  &.withBanner {
    padding-top: 150px;

    position: relative;
    color: white;
    h1,
    a {
      color: white;
    }
    a:hover {
      font-weight: bold;
    }

    //For titles with banners, making the color of the title text to white in print preview
    @media print {
      h1 {
        color: white;
      }
    }
  }
}
