@use "../../theme/vars.scss";
.searchItemText {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quickNavigateContainer {
  align-self: baseline;
  overflow-x: hidden;
}

.searchSection {
  scroll-margin-top: 62px;
  border-bottom: 1px solid #e5e5e5;
}

.searchItem {
  transition: none;
  .hintText {
    visibility: hidden;
  }
  &:global(.Mui-selected) {
    .hintText {
      visibility: visible;
    }
  }
  &:hover {
    .hintText {
      visibility: visible;
    }
  }
}

.inputBase {
  padding: 5px 2px;
}

.searchField {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  padding-top: 14px;
  padding-bottom: 7px;
}

.accountChip {
  max-width: 100px;
}
@media (min-width: 900px) {
  // Using the MUI breakpoints here
  .accountChip {
    max-width: 200px;
  }
  .closeButton {
    display: none;
  }
}
