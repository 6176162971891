@use "../../theme/vars.scss";

.footerContainer {
  font-size: 15px;
  color: #b3b3b3;
  @media print {
    display: none;
  }

  a {
    font-size: inherit;
    color: #b3b3b3;
    text-decoration: none;
    &:hover {
      color: #666;
    }
  }
  .footer {
    display: flex;
    flex-wrap: wrap;
    .upperFooter {
      flex: 1 1 auto;
      display: flex;
      margin-bottom: 1rem;
      .pageCollection {
        display: flex;
        padding-bottom: 0;
        flex-direction: column;

        h4.pageHeader {
          font-size: inherit;
          font-weight: bold;
          color: #333;
        }
      }
    }
    .lowerFooter {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      align-items: flex-end;
      justify-content: flex-end;
      margin-left: auto;
      .footerItem {
        .grayIcon {
          padding-right: 0.7rem;
          margin-right: 0.7rem;
          transition: all 0.1s ease-in-out;
          font-size: 0.8rem;
          &:hover svg {
            transform: scale(1.1);
          }
        }
        .footerText {
          text-align: middle;
          padding: 5px;
        }
      }
    }
  }
}
