@use "../../../theme/vars.scss";
.section {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1em;
  h3 {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  > .widgetsContainer {
    margin: 1em;
    text-align: center;
    border-left: 0.5em solid white;
    border-right: 0.5em solid white;

    > .widget {
      margin-left: 0;
      margin-right: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 400px;
      > .measure {
        display: flex;
        flex-direction: column;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        width: 200px;
      }
    }
  }

  > .exceeded {
    border-left: 0.5em solid var(--color-error);
  }
  > .warning {
    border-left: 0.5em solid var(--color-warning);
  }
}

.doubleWidget {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.singleWidget {
  padding-left: 0;
  padding-right: 0;
}

.invisible {
  visibility: hidden;
}

.exceededSymbol {
  color: var(--color-error);
}

.warningSymbol {
  color: var(--color-warning);
}

.literal {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  > * {
    align-self: center;
  }
}
.bold {
  font-weight: bold;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.numberLiteralNumberPart {
  font-size: 4em;
  margin-bottom: -15px;
}
.numberLiteralUnitPart {
  font-size: 1.5em;
}
.dateLiteral {
  font-size: 1.5em;
}
