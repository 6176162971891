@use "../../theme/vars.scss";
.warning {
  label:global(.Mui-error),
  p:global(.Mui-error) {
    color: var(--color-warning);
  }
  :global(.MuiInput-underline.Mui-error:after) {
    border-bottom-color: var(--color-warning);
  }
}

.firstAdornmentFix:global(.MuiInputLabel-formControl) {
  transform: translate(22px, 24px) scale(1);
  &:global(.MuiInputLabel-shrink),
  &:global(.MuiFormLabel-filled) {
    transform: translate(22px, 1.5px) scale(0.75);
  }
}

:global(.Mui-error .MuiInputAdornment-root) {
  color: var(--color-error);
}
