@use "../../theme/vars.scss";

.eyecon {
  color: var(--color-primary);
}

.muiTextFieldReduxWrapper {
  display: flex;
  flex-direction: column;
}
.progressBarWrapper {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: relative;
  margin-top: 0.5rem;

  :global(.MuiLinearProgress-root) {
    height: 0.25rem;
  }
  :global(.MuiLinearProgress-bar2Buffer) {
    background-color: var(--color-gray-lighter);
  }

  .passwordStrength {
    display: flex;
    justify-content: flex-end;
    color: var(--color-gray);
  }
}

.tooltip {
  > div {
    background-color: transparent;
    min-width: max-content;
  }
  .feedback {
    background-color: rgb(248, 247, 247);
    display: flex;
    flex-direction: column;
    padding: 0.75em 0.75em 0.5em 0.75em;
    :global(.MuiAlert-icon) {
      font-size: 1.2em;
      display: flex;
      align-items: center;
      margin-right: 0.25rem;
    }
    .warnings {
      align-items: center;
      & :global(.MuiAlert-root) {
        background-color: transparent;
        width: 100%;
        padding: 0 0.5em;
      }
    }
    ul.suggestions {
      margin-top: 0.5rem;
      padding: 0 0.5em;
      font-size: 0.875rem;
      color: var(--color-primary);
      p {
        margin: 0 0 0.5rem 0;
      }
      & li {
        list-style-type: initial;
        line-height: 140%;
        margin-left: 1.75em;
      }
      & li span.suggeestionItem {
        line-height: 140%;
        padding-top: 1rem;
        margin-bottom: 2em;
        font-weight: normal;
      }
    }
  }
}
