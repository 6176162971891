.pivotTable {
  overflow: auto;
  &.chartRender {
    max-width: 100%;
    overflow: hidden;
  }
  :global {
    .pvtUi {
      table-layout: fixed;
      font-family: inherit;
    }
    table.pvtTable tbody tr th {
      background-color: #ebf0f8;
      border: 1px solid #c8d4e3;
      &.pvtAxisLabel {
        color: white;
        background-color: #1690c6;
      }
    }

    table.pvtTable tbody tr td {
      border: 1px solid var(--color-gray-lighter);
    }
    .pvtAxisContainer,
    .pvtVals {
      border: 1px solid var(--color-gray-lighter);
      background: transparent;
    }
    .pvtDropdownMenu {
      max-height: 100px;
      overflow: auto;
    }
    .pvtDropdownIcon {
      float: right;
      color: var(--color-primary);
    }
    .pvtDropdownCurrent {
      text-align: left;
      border: 1px solid #a2b1c6;
      background: white;
    }

    .pvtDropdownActiveValue {
      background: #ebf0f8;
    }
    .pvtHorizList {
      border-top: none;
    }

    .pvtVals {
      background: transparent;
    }
    .pvtOutput {
      width: 100%;
    }

    .pvtTriangle {
      cursor: pointer;
      color: #506784;
    }

    .pvtCheckContainer {
      border-color: var(--color-gray-lighter);
    }
  }
}
