@use "../../theme/vars.scss";

.container {
  gap: 20px;
  padding: 15px;
}
.colAvatar {
  align-self: flex-start;
  margin-top: 4px;
}
.main {
  flex-grow: 1;
  word-break: break-word;
}
.icons {
  display: flex;
  align-items: center;
  font-size: 130%;
  margin-right: 10px;
}
.description {
  padding: 1em;
  max-height: 180px;
  overflow: hidden;
  &:empty {
    display: none;
  }
}
