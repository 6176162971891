@use "../../../theme/vars.scss";

.title {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-wrap: break-word;
  a {
    align-self: center;
    margin-left: 8px;
  }
}

.graphIcon {
  width: 25px;
}

.graphError {
  padding: 8px;
}

.actionButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  .buttonIcon {
    margin-right: 7px;
  }
}

.centeredTitle {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.graphSummary {
  summary {
    cursor: pointer;
  }
}

.disabled {
  pointer-events: none;
  color: var(--color-gray);
}

.logsCodemirrorWrapper {
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  .codemirror {
    filter: invert(100%);
  }
}

.codemirror {
  font-size: 0.9rem;
  border: 1px solid #eee;
  :global(.cm-gutters) {
    height: 200px;
  }
}

.logControls {
  display: flex;
  justify-content: flex-end;
}

.noHighlight,
.addHighlight {
  border-radius: 6px 0 0 6px;
  border-left-color: var(--color-primary);
  border-left-style: solid;
  transition: border-left-width 200ms ease-in-out;
}
.noHighlight {
  border-left-width: 0px;
}
.addHighlight {
  border-left-width: 5px;
  animation: addHighLight 200ms ease-in-out;
}

@keyframes addHighLight {
  from {
    border-left-width: 0px;
  }
  to {
    border-left-width: 5px;
  }
}
// prob not needed
@media (prefers-reduced-motion) {
  .noHighlight,
  .addHighlight {
    border-radius: 6px 0 0 6px;
    border-left-color: var(--color-primary);
    border-left-style: solid;
  }
  .addHighlight {
    border-left-width: 5px;
  }
}
