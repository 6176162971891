@use "../../theme/vars.scss";

.searchContainer {
  background-color: white;
  border-bottom: #ddd;
}
.resultContainer {
  max-width: calc(100% - 20px);
}
.searchFieldWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.searchField {
  max-width: 750px;
  flex-grow: 1;
  padding: 0;
  input {
    border-radius: 6px;
    max-width: 100%;
  }
  @media all and (-ms-high-contrast: none) {
    max-width: none;
  }
}
.tabWrapper {
  width: 100%;
  max-width: 750px;
  &.query {
    max-width: vars.$screen-lg-min;
  }
  .searchApi {
    font-size: 0.9rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    > input {
      flex-grow: 1;
      background-color: var(--color-gray-lighter);
      border-radius: 2px;
      border: 1px solid var(--color-gray-light);
      border-left: 3px solid var(--color-primary);
      font-family: monospace;
      font-size: 0.7rem;
      font-weight: normal;
      line-height: 1.75em;
      padding: 0.25em 0.75em;
      &:focus {
        outline: none;
      }
    }
  }
  .textSearch {
    width: 100%;
  }
  .advancedSearchField {
    padding: 0;
    max-width: vars.$screen-lg-min;
    position: relative;
    flex-grow: 1;

    .codeMirror {
      border: 1px solid #cfd4d8;
      :global(.cm-gutters) {
        height: 70px;
      }

      > :global(.cm-editor) {
        max-height: 67vh;
      }
    }
    .queryButton {
      position: absolute;
      top: 1rem;
      right: 12px;
      z-index: 10;
      background: none;
      border: none;
      cursor: pointer;

      &:disabled {
        color: var(--color-gray-light);
        cursor: not-allowed;
      }
      &:hover {
        color: var(--color-gray-dark);
      }

      svg {
        width: 3rem;
        height: 3rem;
      }
      .loading {
        stroke-dasharray: 100;
        animation: dash 1.5s linear infinite;
        stroke-width: 8px;
        stroke: white;
        @keyframes dash {
          to {
            stroke-dashoffset: 200;
          }
        }
      }
    }
    .infoButton {
      font-size: 1.6rem;
      position: absolute;
      top: 1rem;
      right: 5rem;
      z-index: 10;
      &:hover {
        color: var(--color-gray-dark);
      }
    }
  }
}

.codemirror {
  border: solid #e6e6e6 1px;
  :global(.CodeMirror) {
    height: auto;
  }
}
.tabs {
  border-bottom: none;
  height: 40px;
  min-height: 40px;
}

.tab {
  min-height: 40px;
  min-width: 100px;
}

.loadingMappings {
  height: 300px;
}
.dropdown {
  min-width: 100px;
  max-width: 200px;
  margin-right: 1rem;
}
