@use "index.scss";

.paragraph {
  @extend .constrainWidth;
}

.authors {
  font-size: 11pt;
  text-align: center;

  .author {
    display: inline-block;
  }

  .author::after {
    content: ",\00a0";
  }
  .author:nth-last-child(2)::after {
    content: ", and\00a0";
  }
  .author:last-child::after {
    content: "";
  }

  .orcid > svg {
    font-size: 16px;
    margin-left: 4px;
    margin-right: 4px;
    color: #a6ce39;
  }
}

.affiliations {
  padding-top: 1em;
  text-align: center;
  font-size: 11pt;
}

.keywords {
  text-align: justify;
  padding: 1em 2.5em;
  h2 {
    display: inline;
  }
}

.abstract {
  text-align: justify;
  padding: 1em 2.5em;
  h2 {
    display: inline;
  }
}
