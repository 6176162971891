.dragHandle {
  cursor: ns-resize;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  line-height: 1px;
  //For the <br/> line spacing
  &:hover {
    // We only want the transition when we start to hover over it, exiting hover should be instant
    :global(.MuiDivider-root) {
      transition-property: border-bottom-width, border-color;
      transition-duration: 250ms;
      transition-timing-function: ease-out;
      border-bottom-width: thick;
      border-color: var(--color-primary);
    }
  }
  &.dragging {
    :global(.MuiDivider-root) {
      border-bottom-width: thick;
      border-color: var(--color-primary);
    }
  }
}
